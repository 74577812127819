import { ThemeProps } from 'components/colors/theme';
import styled, { css } from 'styled-components';

const Regular = styled.button<{ $fullWidth?: boolean }>`
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  ${(props) => (props.$fullWidth ? 'width: 100%' : '')};
`;

export const RegularPrimary = styled(Regular)<{ $tint?: boolean }>`
  padding: 16px 28px;
  background: ${({ $tint, theme }) =>
    $tint ? theme.tint.blue : theme.primary.solid};
  color: ${({ $tint, theme }) => {
    if ($tint) {
      return theme.primary.solid;
    }
    return '#FFFFFF';
  }};
  border: none;
  outline: none;
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background: ${({ theme, $tint }) => {
      if ($tint) {
        return theme.tint.blue;
      }
      return theme.primary.hover;
    }};
    color: ${({ theme, $tint }) => {
      if ($tint) {
        return theme.primary.hover;
      }
      return '#FFFFFF';
    }};
  }
  &:active:focus {
    background: ${({ theme, $tint }) => {
      if ($tint) {
        return theme.tint.blue;
      }
      return theme.primary.hover;
    }};
    color: ${({ theme, $tint }) => {
      if ($tint) {
        return theme.primary.clicked;
      }
    }};
  }

  .rebrand & {
    border-radius: 8px;
  }
`;

export const RegularSecondary = styled(Regular)`
  // gets 1 pixel from the border, therefore it should be 15px instead of 16
  padding: 15px 28px;
  background: ${(props) => props.theme.grey.white};
  border: 1px solid ${(props) => props.theme.grey.mid};
  box-sizing: border-box;
  color: ${(props) => props.theme.text.primary};
  &:disabled {
    color: ${(props) => props.theme.text.tertiary};
  }
  &:hover {
    border: 1px solid ${(props) => props.theme.grey.strong};
  }
  &:active:focus {
    border: 1px solid ${(props) => props.theme.primary.solid};
    color: ${(props) => props.theme.primary.solid};
  }
`;

export const RegularAlert = styled(Regular)`
  // gets 1 pixel from the border, therefore it should be 15px instead of 16
  padding: 15px 28px;
  background: ${(props) => props.theme.red.solid};
  border: 1px solid ${(props) => props.theme.red.solid};
  box-sizing: border-box;
  color: ${(props) => props.theme.grey.white};
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:hover {
    border: 1px solid ${(props) => props.theme.red.hover};
    background: ${(props) => props.theme.red.hover};
  }
  &:active:focus {
    border: 1px solid ${(props) => props.theme.red.clicked};
    background: ${(props) => props.theme.red.clicked};
    color: ${(props) => props.theme.grey.white};
  }
`;

export const RegularWhite = styled(Regular)<{ $variant?: string }>`
  // gets 1 pixel from the border, therefore it should be 15px instead of 16
  padding: 15px 28px;
  background: ${(props) => props.theme.grey.white};
  border: 1px solid ${(props) => props.theme.grey.mid};
  box-sizing: border-box;
  color: ${(props) => props.theme.grey.dark};
  &:disabled {
    border: 1px solid ${(props) => props.theme.grey.strong};
    color: ${(props) => props.theme.text.tertiary};
  }
  &:hover {
    background: ${(props) => props.theme.primary.hover};
    color: ${(props) => props.theme.grey.white};
  }
  &:active:focus {
    background: ${(props) => props.theme.primary.clicked};
    color: ${(props) => props.theme.grey.white};
  }
  ${({ theme, $variant }) => whiteVariantStyles(theme, $variant)}
`;

export const whiteVariantStyles = (theme: ThemeProps, variant = 'default') =>
  ({
    primary: css`
      color: ${theme.primary.solid};
      border: 2px solid ${(props) => props.theme.grey.white};
      &:hover {
        background: ${(props) => props.theme.grey.light};
        color: ${(props) => props.theme.primary.hover};
      }
      &:active:focus {
        background: ${(props) => props.theme.grey.mid};
        color: ${(props) => props.theme.primary.clicked};
      }
    `,
  })[variant];

export type GhostVariants = 'primary' | 'white' | 'grey';

export const ghostVariantStyles = (theme: ThemeProps, variant = 'primary') =>
  ({
    primary: css`
      color: ${theme.primary.solid};
      border: 2px solid ${theme.primary.solid};
      &:hover {
        background: ${theme.primary.hover};
        border-color: ${theme.primary.hover};
        color: ${theme.grey.white};
      }
      &:active:focus {
        background: ${theme.primary.clicked};
        border-color: ${theme.primary.clicked};
        color: ${theme.grey.white};
      }
    `,
    white: css`
      color: ${theme.grey.white};
      border: 2px solid ${theme.grey.white};
      &:hover {
        color: ${theme.grey.white};
      }
    `,
    grey: css`
      color: ${theme.grey.dark};
      border: 2px solid ${theme.grey.strong};
      &:hover {
        background: ${theme.grey.mid};
      }
      &:active:focus {
        background: ${theme.grey.strong};
      }
    `,
  })[variant];

export const RegularGhost = styled(Regular)<{ $variant: string }>`
  // gets two pixels from the border, therefore it should be 14px instead of 16
  padding: 14px 28px;
  background: none;
  box-sizing: border-box;
  &:disabled {
    border: 2px solid ${(props) => props.theme.grey.strong};
    color: ${(props) => props.theme.text.tertiary};
  }
  ${({ theme, $variant }) => ghostVariantStyles(theme, $variant)}
`;

export const RegularTint = styled(Regular)`
  padding: 16px 28px;
  background: ${(props) => props.theme.tint.blue};
  color: ${(props) => props.theme.primary.solid};
  border: none;
  outline: none;
  &:disabled {
    background: ${(props) => props.theme.grey.light};
    border: 1px solid ${(props) => props.theme.grey.mid};
    box-sizing: border-box;
    color: ${(props) => props.theme.text.tertiary};
  }
  &:hover {
    background: ${(props) => props.theme.primary.clicked};
    color: ${(props) => props.theme.grey.white};
  }
  &:active:focus {
    background: ${(props) => props.theme.primary.clicked};
    color: ${(props) => props.theme.grey.white};
  }
`;

export const RegularWidePrimary = styled(RegularPrimary)`
  position: relative;
  padding: 16px 52px;
  background: ${(props) => props.theme.primary.solid};
  color: ${(props) => props.theme.grey.white};
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const RegularCCH = styled(Regular)`
  position: relative;
  padding: 16px 52px;
  background: ${(props) => props.theme.social.cch};
  color: ${(props) => props.theme.grey.white};
  border: none;
  outline: none;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  &:disabled {
    opacity: 0.5;
    color: ${(props) => props.theme.grey.white};
  }
  &:hover {
    color: ${(props) => props.theme.grey.white};
  }
  &:active:focus {
    color: ${(props) => props.theme.grey.white};
  }
`;

export const RegularGoogle = styled(Regular)`
  position: relative;
  padding: 15px 52px;
  background: ${(props) => props.theme.grey.white};
  border: 1px solid ${(props) => props.theme.grey.mid};
  box-sizing: border-box;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.text.primary};
  &:disabled {
    background: ${(props) => props.theme.grey.white};
    color: ${(props) => props.theme.text.tertiary};
  }
  &:hover {
    border: 1px solid ${(props) => props.theme.grey.strong};
  }
  &:active:focus {
    border: 1px solid ${(props) => props.theme.primary.solid};
    color: ${(props) => props.theme.primary.solid};
  }
`;

export const InlineButton = styled.button<{ $marginForSVG?: string }>`
  outline: none;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  line-height: inherit;
  color: inherit;
  font-weight: inherit;
  svg {
    margin-left: ${({ $marginForSVG = '8px' }) => $marginForSVG};
  }
`;

export const ButtonLink = styled(InlineButton)`
  color: ${({ theme }) => theme.primary.solid};
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.primary.hover};
  }
  &:active,
  &:focus {
    color: ${(props) => props.theme.primary.clicked};
  }
`;
