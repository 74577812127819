import React from 'react';
import { useAuth } from 'src/app/router';
import { StatsigProvider } from 'statsig-react';
import { getUserDataFromCookies } from './utils';

interface CtStatsigProviderProps {
  children: React.ReactNode;
}

// Our statsig provider needs to take the user data in synchronously or it will do multiple queries and fetch the wrong data.
// We decided instead of using the mountKey approach for statsig to receive all necessary data in via cookies to make this as fast
// as possible.
export const CtStatsigProvider = ({ children }: CtStatsigProviderProps) => {
  const { isAuthenticated } = useAuth();
  const userDataFromCookies = getUserDataFromCookies();

  return (
    <StatsigProvider
      sdkKey={process.env.STATSIG_CLIENT_KEY}
      // setting waitForInitialization to true, unmounts the parent components and forces a re-render to the whole app
      // which creates a bunch of issues and doesn't seem to be stable enough for use.
      waitForInitialization={false}
      user={{
        userID: userDataFromCookies.userId,
        customIDs: {
          anonymousID: userDataFromCookies.anonymousId,
        },
        custom: {
          useV2Lots: true,
          createdAt: userDataFromCookies.createdAt,
          taxProsReturnPublicId: userDataFromCookies.taxProsReturnPublicId,
          isAnonymous: !isAuthenticated,
          is_employee: userDataFromCookies.isEmployee,
        },
      }}
    >
      {children}
    </StatsigProvider>
  );
};
