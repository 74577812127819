import { createGlobalStyle } from 'styled-components';

// Flagging this as being overridden by Tailwind. This causes a bunch of style updates to our existing product.
export const GlobalStyles = createGlobalStyle<{
  rebrand?: boolean;
}>`
  :root {
    font-family: NB-International !important;
  }
`;
