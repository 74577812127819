export const PartnerTypes = {
  INTUIT: {
    slug: 'intuit',
    niceName: 'Intuit',
  },
  CCH: {
    slug: 'cch',
    niceName: 'CCH',
  },
  OPENSEA: {
    slug: 'opensea',
    niceName: 'OpenSea',
  },
  COINSQUARE: {
    slug: 'coinsquare',
    niceName: 'Coinsquare',
  },
  HRBLOCK: {
    slug: 'hrblock',
    niceName: 'H&R Block',
  },
  UPHOLD: {
    slug: 'uphold',
    niceName: 'Uphold',
  },
  BLOCKCHAINCOM: {
    slug: 'blockchain.com',
    niceName: 'Blockchain.com',
  },
  PHANTOM: {
    slug: 'phantom',
    niceName: 'Phantom',
  },
  ETORO: {
    slug: 'etoro',
    niceName: 'eToro',
  },
  ETOROCLUB: {
    slug: 'etoroclub',
    niceName: 'eToro Club',
  },
  TAXCADDY: {
    slug: 'taxcaddy',
    niceName: 'TaxCaddy',
  },
  MOONTAX: {
    slug: 'moontax',
    niceName: 'MoonTax',
  },
  SUREPREP: {
    slug: 'sureprep',
    niceName: 'SurePrep',
  },
  THE_NETWORK_FIRM: {
    slug: 'the-network-firm',
    niceName: 'The Network Firm',
  },
  TAXING_CRYPTOCURRENCY: {
    slug: 'taxing-cryptocurrency',
    niceName: 'Taxing Cryptocurrency',
  },
  METAMASK: {
    slug: 'metamask',
    niceName: 'MetaMask',
  },
  BE_PRO_CRYPTO: {
    slug: 'be-pro-crypto',
    niceName: 'Be Pro Crypto',
  },
  PICNIC_TAX: {
    slug: 'picnic-tax',
    niceName: 'Picnic Tax',
  },
  WOLTERS_KLUWER: {
    slug: 'wk',
    niceName: 'Wolters Kluwer',
  },
  COINBASE: {
    slug: 'coinbase',
    niceName: 'Coinbase',
  },
  COINBASE_ONE: {
    slug: 'coinbaseone',
    niceName: 'Coinbase One',
  },
  BRAVE: {
    slug: 'brave',
    niceName: 'Brave',
  },
  TRUST_WALLET: {
    slug: 'trust-wallet',
    addWalletPageSlug: 'trust',
    niceName: 'Trust Wallet',
  },
  UNISWAP: {
    slug: 'uniswap',
    niceName: 'Uniswap',
  },
  GORDON_LAW: {
    slug: 'gordon-law',
    niceName: 'Gordon Law',
  },
  TURBO_TAX: {
    slug: 'turbotax',
    niceName: 'TurboTax',
  },
  SOLANA: {
    slug: 'solana',
    niceName: 'Solana',
  },
  COINJAR: {
    slug: 'coinjar',
    niceName: 'CoinJar',
  },
} as const;

export function extractPartnerSlug(url: string) {
  const pattern = /^\/partner\/([^/]+)$/;
  const match = url.match(pattern);

  if (match) {
    return match[1];
  }

  return null;
}

export const getPartnerFromPathname = (pathname: string) => {
  const foundPartnerSlug = extractPartnerSlug(pathname);

  if (foundPartnerSlug) {
    const partnerSlugExists = Object.values(PartnerTypes).some(
      ({ slug }) => slug === foundPartnerSlug,
    );
    if (partnerSlugExists) {
      return foundPartnerSlug;
    }
    return undefined;
  }

  const matchingPathname = landingStaticPaths.find(
    (path) => `/${path.params.landing}` === pathname,
  );
  if (matchingPathname) {
    return matchingPathname.props?.partner;
  }

  // Special condition for Coinbase-One promotion
  if (pathname === '/promotion/coinbase-one') {
    return PartnerTypes.COINBASE_ONE.slug;
  }

  return undefined;
};

// we moved those here because it's needed by both the analytics package and the landing app and it would create a
// circular dependency.
export const landingStaticPaths = [
  { params: { landing: 'landing' } },
  { params: { path: undefined } },
  { params: { landing: 'canada' }, props: { partner: 'canada' } },
  { params: { landing: 'taxes-uk' }, props: { partner: 'taxes-uk' } },
  {
    params: { landing: 'hrblock' },
    props: { partner: PartnerTypes.HRBLOCK.slug },
  },
  {
    params: { landing: 'phantom' },
    props: {
      partner: PartnerTypes.PHANTOM.slug,
      description:
        'Connect Phantom, plus your other wallets and exchanges, to get your crypto tax reports in minutes.',
    },
  },
  {
    params: { landing: 'opensea' },
    props: { partner: PartnerTypes.OPENSEA.slug },
  },
  {
    params: { landing: 'etoro' },
    props: {
      partner: PartnerTypes.ETORO.slug,
      description:
        'Connect Etoro, plus your other wallets and exchanges, to get your crypto tax reports in minutes.',
    },
  },
  {
    params: { landing: 'etoroclub' },
    props: { partner: PartnerTypes.ETOROCLUB.slug },
  },
  {
    params: { landing: 'blockchain' },
    props: { partner: PartnerTypes.BLOCKCHAINCOM.slug },
  },
  {
    params: { landing: 'coinsquare' },
    props: { partner: PartnerTypes.COINSQUARE.slug },
  },
  {
    params: { landing: 'uphold' },
    props: {
      partner: PartnerTypes.UPHOLD.slug,
      description:
        'Connect Uphold, plus your other wallets and exchanges, to get your crypto tax reports in minutes.',
    },
  },
  {
    params: { landing: 'coinbaseone' },
    props: {
      partner: PartnerTypes.COINBASE_ONE.slug,
      description:
        'Connect Coinbase or Coinbase Wallet, plus your other wallets and exchanges, to get your crypto tax reports in minutes.',
    },
  },
  {
    params: { landing: 'metamask' },
    props: {
      partner: PartnerTypes.METAMASK.slug,
      description:
        'Connect MetaMask, plus your other wallets and exchanges, to get your crypto tax reports in minutes.',
    },
  },
  {
    params: { landing: 'turbotax' },
    props: { partner: PartnerTypes.TURBO_TAX.slug },
  },
  {
    params: { landing: 'coinbase' },
    props: {
      partner: PartnerTypes.COINBASE.slug,
      description:
        'Connect Coinbase or Coinbase Wallet, plus your other wallets and exchanges, to get your crypto tax reports in minutes.',
    },
  },
  {
    params: { landing: 'coinjar' },
    props: {
      partner: PartnerTypes.COINJAR.slug,
      description:
        'Connect CoinJar, plus your other wallets and exchanges, to get your crypto tax reports in minutes.',
    },
  },
];
