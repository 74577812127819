import {
  QueuePriority,
  useEnqueueCostBasisMutation,
} from '@cointracker/graphql-types';
import { Status, ToastContext } from '@cointracker/styleguide';
import { Body, Button, Heading } from '@cointracker/styleguide/src/LoggedIn';
import { Avatar } from '@cointracker/styleguide/src/LoggedIn/Avatar';
import { URLS } from '@cointracker/ui';
import { CardsThree } from '@phosphor-icons/react';
import { useCallback, useContext } from 'react';
import { useAnalytics } from 'src/app/analytics';
import { formatWalletLabel } from 'src/common/wallet-address';
import AddWalletButton from 'src/pages/Rebrand/Wallets/WalletsHeader/AddWalletButton';
import { StatusIndicator } from '../StatusIndicator';
import {
  StatusReadyState,
  SyncAssetItem,
  useGetStatus,
} from '../hooks/useGetStatus';
import { getExpandedStatusImage, getExpandedStatusText } from '../utils';

export function StatusSheetContent() {
  const { status } = useGetStatus();
  const image = getExpandedStatusImage(status);
  return (
    <div className="flex h-[calc(100%_-_var(--topbar-height))] w-full flex-col overflow-auto">
      <div className="flex w-full max-w-[--sidebar-width-status] flex-col gap-8 p-16 md:p-40">
        {image && (
          <div className="mb-16 w-full overflow-hidden rounded-16 md:w-auto">
            <img src={image.src} alt={image.src} className="w-full" />
          </div>
        )}
        <Heading variant="h5" className="mb-8 text-text-primary">
          {getExpandedStatusText(status)}
        </Heading>
        <Body variant="body3" className="text-text-secondary">
          {StatusSheetDescription(status)}
        </Body>
      </div>
      <div className="mt-auto px-40 py-16">
        <StatusSheetAction />
      </div>
    </div>
  );
}

function StatusSheetDescription(status: StatusReadyState) {
  switch (status) {
    case StatusReadyState.Calculating:
      return "We're reviewing your transactions and calculating your tax, portfolio, and performance values. Your balances and cost basis may change while this is happening.";
    case StatusReadyState.Syncing:
      return 'Your wallets and exchanges are regularly synced so your CoinTracker account can reflect your latest transactions and balances.';
    case StatusReadyState.EmptyData:
      return 'Add your first wallet or exchange in just a couple clicks. We only need read access.';
    case StatusReadyState.Failed:
      return 'We were not able to calculate your tax, portfolio, and performance values. Your balances and cost basis may not be up-to-date right now.';
    case StatusReadyState.Ready:
    default:
      return 'Your balances and cost basis are up-to-date.';
  }
}

function StatusSheetAction() {
  const { status, syncingAssets, shouldAllowUserToResync } = useGetStatus();
  const { showNotification } = useContext(ToastContext);
  const analytics = useAnalytics();
  const onSupportButtonClicked = () => {
    analytics.track('Sidebar Support Button Clicked');
    window.open(URLS.SUPPORT, '_blank', 'noopener,noreferrer');
  };
  const [mutate, { loading }] = useEnqueueCostBasisMutation({
    onCompleted: (data) => {
      if (data.enqueueCostBasis.success) {
        showNotification({
          status: Status.Success,
          message: 'Enqueued portfolio and tax calculations.',
          delay: 4000,
        });
      } else {
        showNotification({
          status: Status.Error,
          message:
            'There was a problem while enqueuing portfolio and tax calculations. Please try again',
        });
      }
    },
    onError: () => {
      showNotification({
        status: Status.Error,
        message:
          'There was a problem while enqueuing portfolio and tax calculations. Please try again',
      });
    },
  });

  const resync = useCallback(() => {
    if (loading) {
      return;
    }
    analytics.track('Sidebar Resync Button Clicked');
    mutate({ variables: { priority: QueuePriority.High } });
  }, [mutate, loading, analytics]);

  switch (status) {
    case StatusReadyState.Calculating:
      return (
        <div className="flex w-full flex-row place-items-center gap-12">
          <div className="flex h-40 w-40 flex-col place-items-center justify-center rounded-full bg-background-alternate-consistent text-text-primary-foreground-inverse-consistent">
            <CardsThree size={18} />
          </div>
          <Body
            weight="bold"
            variant="body2"
            className="flex-1 truncate text-text-primary"
          >
            All wallets and exchanges
          </Body>
          {/* design shows 40px but it takes a bit of extra space so 38 seems to cover the avatar on the left better */}
          <StatusIndicator status={status} circleClass="h-[38px]" />
        </div>
      );
    case StatusReadyState.Syncing:
      if (syncingAssets.length === 0) return null;
      return (
        <ul className="flex max-h-[80dvh] max-w-[352px] flex-col">
          {syncingAssets.map((asset) => (
            <li key={asset.url} className="py-8 pr-8">
              <SyncedAsset asset={asset} />
            </li>
          ))}
        </ul>
      );
    case StatusReadyState.Failed:
      return (
        <div className="flex flex-row gap-8">
          <Button onClick={onSupportButtonClicked}>Contact Support</Button>
          {shouldAllowUserToResync && (
            <Button variant="line" onClick={resync} disabled={loading}>
              Resync
            </Button>
          )}
        </div>
      );
    case StatusReadyState.EmptyData:
      return <AddWalletButton />;
    case StatusReadyState.Ready:
    default:
      return null;
  }
}

function SyncedAsset({ asset }: { asset: SyncAssetItem }) {
  return (
    <div className="flex flex-row items-center gap-12">
      <Avatar
        src={asset.imgUrl}
        fallbackText={asset.title}
        alt={`${asset.title} logo`}
        size="sm"
        variant="image"
        className="rounded-8"
      />
      <div className="flex flex-1 flex-col">
        <Body
          weight="bold"
          variant="body2"
          className="inline truncate text-text-primary"
        >
          {asset.title}
        </Body>
        <Body variant="body4" className="inline truncate text-text-secondary">
          {formatWalletLabel(asset.subtitle)}
        </Body>
      </div>
      <StatusIndicator
        status={
          asset.isSyncing ? StatusReadyState.Syncing : StatusReadyState.Ready
        }
      />
    </div>
  );
}
