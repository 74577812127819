import adaEmbed from '@ada-support/embed2';
import { SubscriptionPlan } from '@cointracker/graphql-types';
import {
  cn,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  useSidebar,
} from '@cointracker/styleguide';
import { Body, Tags } from '@cointracker/styleguide/src/LoggedIn';
import { URLS } from '@cointracker/ui';
import { CaretRight } from '@phosphor-icons/react';
import { useCallback, useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { UserContext } from 'src/app/user/context';
import { useOnLogout } from '../hooks/useOnLogout';
import { getAccountNavItem, isAuthenticatedUser } from '../utils';
import ViewAsModal from '../ViewAsModal';

interface AccountBarProps {
  iconOnly?: boolean;
}

export const AccountBar = ({ iconOnly = false }: AccountBarProps) => {
  const [isAdminModalOpen, setAdminModalOpen] = useState(false);
  const { isMobile } = useSidebar();
  const user = useContext(UserContext);
  const onLogout = useOnLogout();

  const onClickViewAs = useCallback(() => {
    if (!isAuthenticatedUser(user) || !user.isAdmin) {
      return;
    }
    setAdminModalOpen(true);
  }, [user]);

  const onCloseAdminModal = useCallback(() => {
    setAdminModalOpen(false);
  }, []);

  const onClickChatWithSupport = useCallback(async () => {
    if (!isAuthenticatedUser(user)) return;
    try {
      // allow users to toggle the chatbot from account nav menu
      if ((await adaEmbed.getInfo()).hasActiveChatter) return adaEmbed.stop();
    } catch {
      // can't get info on adaEmbed if it isn't started
    }
    const subscriptionPlan = user.unifiedSubscription?.subscriptionPlan ?? '';
    await adaEmbed?.start({
      handle: 'cointracker',
      metaFields: {
        meta_user_authorized: user.isAuthenticated,
        meta_ct_user_2FA_enabled: user.isTwoStepVerificationEnabled,
        meta_ct_user_email: user.email,
        meta_ct_portfolio_subscription: user.portfolioSubscription,
        meta_ct_unified_plan:
          subscriptionPlan.charAt(0).toUpperCase() +
          subscriptionPlan.substring(1).toLowerCase(),
      },
    });
    await adaEmbed.toggle();
  }, [user]);

  const onNavigateToSupport = () => {
    window.open(URLS.SUPPORT, '_blank', 'noopener,noreferrer');
  };

  if (!isAuthenticatedUser(user)) {
    return null;
  }

  const accountNavItems = getAccountNavItem(
    user.displayUser.email,
    user.isAdmin,
    onNavigateToSupport,
    onClickChatWithSupport,
    onClickViewAs,
    onLogout,
    location.pathname,
  );
  const subscriptionPlan = user.unifiedSubscription?.subscriptionPlan;
  const userIconClassName = getUserAvatar({ subscriptionPlan });
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <div className="flex cursor-pointer flex-row place-items-center gap-12 hover:text-text-primary-hover md:gap-8">
            {accountNavItems.icon({
              weight: accountNavItems.isActive ? 'fill' : 'regular',
              className: accountNavItems.isUserIcon && userIconClassName,
            })}
            {!iconOnly && (
              <>
                <Body
                  variant={isMobile ? 'body1' : 'body5'}
                  className={cn(
                    'block flex-1 truncate text-left transition-opacity group-data-[state=collapsed]:opacity-0',
                    // fade animation timing
                    'delay-300 duration-150 ease-in group-data-[state=collapsed]:delay-0 group-data-[state=collapsed]:ease-out',
                    iconOnly,
                  )}
                >
                  {accountNavItems.title}
                </Body>
                <NavLink
                  to={URLS.UNIFIED_SUBSCRIPTION_PLANS_PAGE}
                  onKeyDown={(e) => e.stopPropagation()}
                  onPointerDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                  className={cn(
                    'cursor-pointer transition-opacity group-data-[state=collapsed]:opacity-0',
                    // fade animation timing
                    'delay-300 duration-150 ease-in group-data-[state=collapsed]:delay-0 group-data-[state=collapsed]:ease-out',
                    iconOnly,
                  )}
                >
                  <UnifiedPlanTag subscriptionPlan={subscriptionPlan} />
                </NavLink>
                <CaretRight className="md:hidden" weight="bold" size={20} />
              </>
            )}
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="group"
          data-state={'expanded'}
          side={isMobile ? 'bottom' : 'right'}
          align="end"
          sideOffset={isMobile ? 4 : 24}
        >
          {accountNavItems.subItems.map((item) => (
            <AccountNavItem key={item.title} item={item} />
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
      {user.isAuthenticated && user.isAdmin && (
        <ViewAsModal isOpen={isAdminModalOpen} onClose={onCloseAdminModal} />
      )}
    </>
  );
};

function UnifiedPlanTag({ subscriptionPlan }: { subscriptionPlan: string }) {
  const unifiedPlan = subscriptionPlan ?? SubscriptionPlan.Free;
  const unifiedPlanTitle =
    `${unifiedPlan.charAt(0).toUpperCase()}${unifiedPlan.substring(1).toLowerCase()}`.replace(
      '_plus',
      '+',
    );
  let unifiedPlanClassName =
    'hover:bg-accent-bold-lilac hover:border-accent-bold-lilac hover:text-text-primary-consistent';
  switch (unifiedPlan) {
    case SubscriptionPlan.Base:
    case SubscriptionPlan.BasePlus:
      unifiedPlanClassName =
        'hover:bg-accent-bold-lime hover:border-accent-bold-lime hover:text-text-primary-consistent';
      break;
    case SubscriptionPlan.Prime:
    case SubscriptionPlan.PrimePlus:
      unifiedPlanClassName =
        'hover:bg-accent-bold-blue hover:border-accent-bold-blue hover:text-text-primary-consistent-inverse';
      break;
    case SubscriptionPlan.Ultra:
    case SubscriptionPlan.UltraPlus:
      unifiedPlanClassName =
        'hover:bg-accent-bold-teal hover:border-accent-bold-teal hover:text-text-primary-consistent-inverse';
      break;
    default:
      break;
  }
  return (
    <Tags color="black" condensed filled className={unifiedPlanClassName}>
      {unifiedPlanTitle}
    </Tags>
  );
}

function getUserAvatar({ subscriptionPlan }: { subscriptionPlan: string }) {
  const unifiedPlan = subscriptionPlan ?? SubscriptionPlan.Free;
  let unifiedPlanClassName =
    'bg-lilac-10 border-line-tertiary border-solid border hover:text-text-primary-consistent';
  switch (unifiedPlan) {
    case SubscriptionPlan.Base:
    case SubscriptionPlan.BasePlus:
      unifiedPlanClassName =
        'bg-lime-10 border-line-tertiary border-solid border hover:text-text-primary-consistent';
      break;
    case SubscriptionPlan.Prime:
    case SubscriptionPlan.PrimePlus:
      unifiedPlanClassName =
        'bg-blue-10 border-line-tertiary border-solid border hover:text-text-primary-consistent-inverse';
      break;
    case SubscriptionPlan.Ultra:
    case SubscriptionPlan.UltraPlus:
      unifiedPlanClassName =
        'bg-green-5 border-line-tertiary border-solid border hover:text-text-primary-consistent-inverse';
      break;
    default:
      break;
  }

  return unifiedPlanClassName;
}
interface AccountSubItem {
  slug: string | null;
  title: string;
  subTitle?: string;
  isActive: boolean;
  icon: () => React.ReactNode;
  action?: () => void;
  hide?: boolean;
}
interface AccountNavItemProps {
  item: AccountSubItem;
  className?: string;
}

function AccountNavItem({ item, className }: AccountNavItemProps) {
  const { isMobile, setOpenMobile } = useSidebar();
  const content = item.subTitle ? (
    <div className="flex max-w-space-160 flex-col gap-x-8">
      <Body variant="body3">{item.title}</Body>
      <Body variant="body5" className="inline truncate">
        {item.subTitle}
      </Body>
    </div>
  ) : (
    <Body variant="body3">{item.title}</Body>
  );
  if (item.slug) {
    return (
      <NavLink
        to={item.slug}
        className={cn(
          'flex flex-row place-items-center gap-8 text-text-secondary hover:text-text-primary-hover active:text-text-primary-foreground-hover',
          item.isActive && 'text-text-primary',
          className,
        )}
        onClick={() => {
          item?.action?.();
          if (isMobile) {
            setOpenMobile(false);
          }
        }}
      >
        <DropdownMenuItem
          className={cn(
            'h-52 min-h-52 w-full cursor-pointer py-0',
            item.hide && 'hidden',
          )}
        >
          {item.icon()}
          {content}
        </DropdownMenuItem>
      </NavLink>
    );
  }
  return (
    <DropdownMenuItem
      className={cn(
        'h-52 min-h-52 py-0',
        'flex cursor-pointer flex-row place-items-center gap-8 text-text-secondary hover:cursor-pointer hover:text-text-primary-hover',
        item.isActive && 'text-text-primary',
        item.hide && 'hidden',
        className,
      )}
      onClick={() => {
        item?.action?.();
        if (isMobile) {
          setOpenMobile(false);
        }
      }}
    >
      {item.icon()}
      {content}
    </DropdownMenuItem>
  );
}
