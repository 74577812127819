import {
  AnalyticsBrowser,
  type EventProperties,
  type SegmentEvent,
  type UserTraits,
} from '@segment/analytics-next';
import type { SegmentFacade } from '@segment/analytics-next/dist/types/lib/to-facade';
import mixpanel from 'mixpanel-browser';
import { v4 as uuid } from 'uuid';
import AnalyticsProxy from './proxy';
import { AnalyticsStore } from './store';
import { type Maybe, type User } from './types';
import {
  getCookie,
  isMobile,
  isServer,
  registerAnalyticsFirstTouch,
} from './utils';

// Maximum amount of time to wait before using server lib
const CLIENT_LOAD_TIMEOUT_MS = 1500;

type voidFunc = () => void;
type clientLoadedFunc = (value: boolean) => void;

export enum CTClient {
  webApp = 'web-app',
  businessApp = 'business-app',
}

export enum CTSubClient {
  webDesktop = 'web-app-desktop',
  webMobile = 'web-app-mobile',
}

/**
 * Client Analytics library
 *
 * Attempts to use the segment client library. If blocked by the browser, then proxies all calls
 * to the server where calls and then sent over to Segment.
 *
 * This library can be loaded asyncronously. It will handle buffering any events that are called while
 * the library is still loading.
 */
export default class Analytics {
  clientLib: AnalyticsBrowser;
  proxyLib: AnalyticsProxy;
  loaded: Maybe<boolean> = null;
  shouldUseClientLib: Maybe<boolean> = null;
  shouldLoadMixpanelReplays: Maybe<boolean> = false;
  buffer: (voidFunc | undefined)[] = [];
  isUserSignedIn: Maybe<boolean> = null;
  client: Maybe<CTClient> = null;

  constructor() {
    this.clientLib = new AnalyticsBrowser();
    this.proxyLib = new AnalyticsProxy();
  }

  init(
    writeKey: string,
    mixpanelToken: string,
    user: User,
    client: CTClient,
    cdnURL?: string,
  ) {
    if (isServer()) return;

    this.client = client;

    const { subscribe } = AnalyticsStore;
    subscribe((state) => {
      if (state.hasUserGivenConsent && state.areOptionalAnalyticsAllowed) {
        this.updateURLWithIRClickId();
        this.load(writeKey, mixpanelToken, user, client, cdnURL);
      }
      if (state.hasUserGivenConsent && !state.areOptionalAnalyticsAllowed) {
        this.reset();
      }
    });
    console.info(
      '[Analytics]: Waiting on consent to initialise analytics script',
    );

    registerAnalyticsFirstTouch();

    window.addEventListener('beforeunload', () => {
      if (AnalyticsStore.getState().areOptionalAnalyticsAllowed) {
        this.forceFlush();
      }
    });
  }

  get lib() {
    return this.shouldUseClientLib ? this.clientLib : this.proxyLib;
  }

  /**
   * The load is an async method. However, you should not wait for the load to start using the analytics methods.
   *
   * The load will attempt to load the client segment library. If it gets blocked by the browser or adblock,
   * library will instead proxy calls over to the server to perform the tracking.
   *
   * After loading the library identifies the user and flushes out any events that were called during the loading
   * process.
   */
  async load(
    writeKey: string,
    mixpanelToken: string,
    user: User,
    client: CTClient,
    cdnURL?: string,
    isEnvProduction?: boolean,
  ) {
    if (isServer()) return;

    this.loaded = false;
    this.client = client;

    // Attempt to load the client library. Set a timer, if gets blocked by adblocker, then use server lib
    // We determine if the client library loaded succesfully by utilizing the ready method from segment
    let clientLibIsReady: clientLoadedFunc;
    const isClientLibReady = new Promise<boolean>((resolve) => {
      clientLibIsReady = resolve;
    });
    const timer = new Promise<boolean>((resolve) =>
      setTimeout(() => {
        resolve(false);
      }, CLIENT_LOAD_TIMEOUT_MS),
    );
    this.clientLib.load({ writeKey, cdnURL }, { obfuscate: true });

    // If the client lib gets block, ready won't be called.
    this.clientLib.ready(() => {
      clientLibIsReady(true);
    });

    this.shouldUseClientLib = await Promise.race([isClientLibReady, timer]);
    console.info(`Client analytics lib is loaded: ${this.shouldUseClientLib}`);

    this.loaded = true;

    // Mixpanel: Since we are using Segment "Actions" with Mixpanel as Destination there is no client-side.
    // Adding SDK snippet to make Mixpanel Replays work.
    // If Segment is loaded, then we can be sure that Mixpanel is also loaded.

    this.shouldLoadMixpanelReplays = !!mixpanelToken && !!isEnvProduction;

    if (this.shouldLoadMixpanelReplays) {
      mixpanel.init(mixpanelToken, { record_sessions_percent: 10 });
      console.log('Mixpanel is loaded');
    } else {
      console.error('Mixpanel not loaded because not in production');
    }

    this.postLoadInit(user);
  }

  /**
   * Resets the entire library and clears any cookies. Only call this during logged out flow.
   */
  reset() {
    if (isServer()) return;

    if (this.shouldUseClientLib) {
      this.clientLib.reset();
      // Mixpanel cookie needs to be cleared separately:
      // https://segment.com/docs/destinations/mixpanel/#reset-mixpanel-cookies
      window.mixpanel?.cookie?.clear();
    }

    this.shouldUseClientLib = null;
    this.buffer = [];
    this.isUserSignedIn = null;
    this.loaded = null;
  }

  identify(userId: string, userTraits: UserTraits = {}) {
    if (isServer()) return;

    const isBufferNeeded = this.bufferIfNeed(() =>
      this.identify(userId, userTraits),
    );
    if (isBufferNeeded) return;

    this.lib.identify(userId, userTraits);
  }

  page(
    pageCategory?: string,
    pageName?: string,
    eventProperties: EventProperties = {},
  ) {
    if (isServer()) return;

    const isBufferNeeded = this.bufferIfNeed(() =>
      this.page(pageCategory, pageName, eventProperties),
    );
    if (isBufferNeeded) return;

    const mergedProperties = {
      ...this.getDefaultProperties(),
      ...eventProperties,
    };

    this.lib.page(pageCategory, pageName, mergedProperties);
  }

  track(
    eventName: string | SegmentEvent,
    eventProperties: EventProperties = {},
  ) {
    if (isServer()) return;

    const isBufferNeeded = this.bufferIfNeed(() =>
      this.track(eventName, eventProperties),
    );
    if (isBufferNeeded) return;

    const mergedProperties = {
      ...this.getDefaultProperties(),
      ...eventProperties,
    };

    this.lib.track(eventName, mergedProperties);
  }

  /**
   * Only use this method if you need to force flush any events in the buffer.
   *
   * This will disregard whether the client lib is loading and use use the server lib
   * to save the events
   *
   * One instance to use this method is right before the user closes a tab or window.
   * This way we don't lose events if the client lib is still loading.
   */
  forceFlush() {
    if (isServer()) return;

    if (this.buffer.length === 0) return;

    if (this.loaded) this.flushBuffer();

    // Temp override the the loaded property and use the server lib to write the events
    this.loaded = true;
    this.flushBuffer();
    this.loaded = false;
  }

  private getAnonymousId() {
    let anonymousId = localStorage.getItem('anonymous_id');
    if (!anonymousId) {
      anonymousId = uuid();
      localStorage.setItem('anonymous_id', anonymousId);
      localStorage.setItem('ajs_anonymous_id', anonymousId);
    }
    return anonymousId;
  }

  private postLoadInit(user: User) {
    this.isUserSignedIn = user?.isAuthenticated;

    const anonymousId = this.getAnonymousId();
    this.lib.setAnonymousId(anonymousId);
    this.addStatsigMiddleware();

    if (!this.shouldUseClientLib) {
      this.proxyLib.anonymousId = anonymousId;
      this.proxyLib.userId = user?.isAuthenticated ? user?.publicId : null;
    }

    // if the user is signed in then call identify with the user id else don't
    if (user?.isAuthenticated) {
      this.identify(user?.publicId, { email: user?.email });
    }

    this.flushBuffer();
  }

  private bufferIfNeed(func: voidFunc) {
    if (!this.loaded) {
      this.buffer.push(func);
      return true;
    }
    return false;
  }

  private flushBuffer() {
    this.buffer.forEach((flush) => {
      flush?.();
    });
    this.buffer = [];
  }

  private addMixpanelReplays(payload: SegmentFacade) {
    // This is for Mixpanel Session Replays:
    // https://docs.mixpanel.com/docs/session-replay/session-replay-web#segment-analyticsjs
    if (window.mixpanel) {
      if (payload.obj.type === 'track' || payload.obj.type === 'page') {
        const segmentDeviceId = this.getAnonymousId();

        mixpanel.register({
          $device_id: segmentDeviceId,
          distinct_id: segmentDeviceId,
        });

        const sessionReplayProperties =
          mixpanel.get_session_recording_properties();
        payload.obj.properties = {
          ...payload.obj.properties,
          ...sessionReplayProperties,
        };
      }
      if (payload.obj.type === 'identify') {
        const userId = payload.obj.userId;
        if (userId) {
          mixpanel.identify(userId); // eslint-disable-line no-undef
        }
      }
    } // END of Mixpanel Session Replays code
  }

  private addStatsigMiddleware() {
    if (!this.shouldUseClientLib) return; // server lib doesn't support this

    this.clientLib.addSourceMiddleware(({ payload, next }) => {
      if (!payload.obj.properties) {
        payload.obj.properties = {};
      }

      try {
        if (this.shouldLoadMixpanelReplays) {
          this.addMixpanelReplays(payload);
        } else {
          console.log(
            'Not in production, not adding Mixpanel session replay properties',
          );
        }
      } catch (e) {
        console.error('Error adding Mixpanel session replay properties', e);
      }

      next(payload);
    });
  }

  private updateURLWithIRClickId() {
    // This is important for Brave users, as it strips the irclickid from the URL, so we need to add it back
    // this is crucial for impact.com integration
    const url = new URL(window.location.href);
    const params = url.searchParams;

    if (params.has('impactid') && !params.has('irclickid')) {
      params.set('irclickid', params.get('impactid') as string);
      // Use toString() to convert the URL object back to a string
      window.history.replaceState({}, '', url.toString());
    }
  }

  private getIterableParams(): {
    iterableEmailCampaignId: string | null;
    iterableTemplateId: string | null;
  } {
    return {
      iterableEmailCampaignId: getCookie('iterableEmailCampaignId'),
      iterableTemplateId: getCookie('iterableTemplateId'),
    };
  }

  private getDefaultProperties() {
    const properties: {
      client: Maybe<CTClient>;
      sub_client: CTSubClient;
      campaign_id?: string;
      template_id?: string;
    } = {
      client: this.client,
      sub_client: isMobile() ? CTSubClient.webMobile : CTSubClient.webDesktop,
    };

    const iterableParams = this.getIterableParams();
    if (iterableParams.iterableEmailCampaignId) {
      properties.campaign_id = iterableParams.iterableEmailCampaignId;
    }
    if (iterableParams.iterableTemplateId) {
      properties.template_id = iterableParams.iterableTemplateId;
    }
    return properties;
  }
}
