import styled from 'styled-components';
import { variantStyles } from './variants';

export const H1 = styled.h1<{ $variant?: string }>`
  font-style: normal;
  font-weight: bold;
  font-size: 80px;
  line-height: 96px;
  letter-spacing: 0.2px;
  ${({ theme, $variant }) => variantStyles(theme, $variant)}
  @media (max-width: 1023.99px) {
    font-size: 40px;
    line-height: 120%;

    .rebrand & {
      font-family: NB-International;
      font-size: 40px;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: 0.20000000298023224px;
    }
  }

  .rebrand & {
    font-family: NB-International;
    font-size: 100px;
    font-weight: 700;
    line-height: 110px;
    letter-spacing: 0.20000000298023224px;
  }
`;

export const H2 = styled.h2<{ $variant?: string }>`
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: 0.4px;
  ${({ theme, $variant }) => variantStyles(theme, $variant)}

  .rebrand & {
    font-family: NB-International;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0.4000000059604645px;
  }
  @media (max-width: 1023.99px) {
    .rebrand & {
      font-family: NB-International;
      font-size: 28px;
      font-weight: 700;
      line-height: 33.6px;
      letter-spacing: 0.4000000059604645px;
    }
    font-size: 28px;
  }
`;

export const H3 = styled.h3<{ $variant?: string }>`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: 0.2px;
  ${({ theme, $variant }) => variantStyles(theme, $variant)}

  .rebrand & {
    font-family: NB-International;
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    letter-spacing: 0.20000000298023224px;
  }
  @media (max-width: 1023.99px) {
    .rebrand & {
      font-family: NB-International;
      font-size: 24px;
      font-weight: 700;
      line-height: 28.8px;
      letter-spacing: 0.20000000298023224px;
    }
    font-size: 24px;
  }
`;

export const H4 = styled.h4<{ $variant?: string }>`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.24px;
  ${({ theme, $variant }) => variantStyles(theme, $variant)}

  .rebrand & {
    font-family: NB-International;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.23999999463558197px;
  }
  @media (max-width: 1023.99px) {
    font-size: 16px;
    letter-spacing: 0.4px;

    .rebrand & {
      font-family: NB-International;
      font-size: 16px;
      font-weight: 700;
      line-height: 19.2px;
      letter-spacing: 0.4000000059604645px;
    }
  }
`;

export const H5 = styled.h5<{ $variant?: string; $fontWeight?: string }>`
  font-style: normal;
  font-weight: ${({ $fontWeight }) => $fontWeight ?? 'bold'};
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  ${({ theme, $variant }) => variantStyles(theme, $variant)}

  .rebrand & {
    font-family: NB-International;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
  }
  @media (max-width: 1023.99px) {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;

    .rebrand & {
      font-family: NB-International;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.4000000059604645px;
    }
  }
`;
