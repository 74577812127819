import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  Operation,
  ServerError,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { mergeEdges, readPageEdges } from 'components/Pagination/utils';
import fetch from 'cross-fetch';
import { createdCustomWalletVar } from '../pages/Rebrand/Wallets/WalletsHeader/AddWalletButton/AddWalletModal/IntegrationForm/FileImportForm';
import {
  API_URI,
  LIVE_PRICE_DATA_API_URI,
  LIVE_PRICE_DATA_OPERATION_NAME,
} from './constants';
import { isUserAuthenticated, logout } from './router';

export const apiUri = ({ operationName }: Operation) => {
  // For price data we fetch from a different endpoint
  if (operationName === LIVE_PRICE_DATA_OPERATION_NAME) {
    return `${LIVE_PRICE_DATA_API_URI}?method=${operationName}`;
  }
  return `${API_URI}?method=${operationName}`;
};
const httpLink = new HttpLink({
  uri: apiUri,
  fetch,
});

// Check if the server responded back with a 401 if so and we are logged in, then logout the SPA
const logoutLink = onError(({ networkError }) => {
  const isUserLoggedIn = isUserAuthenticated();
  // Apollo type issue requires us to cast to ServerError - https://github.com/apollographql/apollo-client/issues/7293#issuecomment-731618265
  if (
    networkError &&
    (networkError as ServerError).statusCode === 401 &&
    isUserLoggedIn
  ) {
    logout();
  }
});
const link = logoutLink.concat(httpLink);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          filteredPrices: {
            keyArgs: ['search'],
            merge: mergeEdges,
            read: readPageEdges,
          },
        },
      },
      UserInfo: {
        keyFields: ['publicId'],
      },
      SearchCurrency: {
        keyFields: ['id', 'symbol'],
      },
      PerformanceDataPoint: {
        keyFields: ['date', 'marketValue', 'costBasis'],
      },
      Flow: {
        // TODO: We need to remove this after testing. Apollo is merging flows cause they have the same id across operations
        //       and operations don't have id's either so there's no good way to make them unique to the eyes of Apollo
        keyFields: ['id', 'amount'],
      },
      Currency: {
        keyFields: (obj) => {
          const key = ['symbol'];
          if (obj?.cmcId) key.push('cmcId');
          if (obj?.name) key.push('name');
          return key;
        },
      },
      // FST-3799: We need to merge syncInfo objects for exchanges and wallets because
      // they have no unique id and we use different fields for them across different queries
      Exchange: {
        fields: {
          syncInfo: {
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming);
            },
          },
        },
      },
      // FST-3799: Same as above but for wallets
      Wallet: {
        fields: {
          syncInfo: {
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming);
            },
          },
        },
      },
      // There's no clear understanding on why it needs both a query type policy and a resolver but no other way worked
      CustomWallet: {
        fields: {
          syncInfo({ willBeDeleted } = {}, props) {
            const createdCustomWallets = createdCustomWalletVar();
            const walletId: string = props.readField('id');
            const hasBeenCreatedRecently = !!createdCustomWallets[walletId];
            return {
              lastSynced: createdCustomWallets[walletId] || null,
              isSyncing: hasBeenCreatedRecently,
              willBeDeleted,
            };
          },
        },
      },
    },
  }),
  resolvers: {
    CustomWallet: {
      syncInfo(wallet) {
        const createdCustomWallets = createdCustomWalletVar();
        return {
          ...wallet.syncInfo,
          lastSynced: createdCustomWallets[wallet.id],
        };
      },
    },
  },
});
