import { Avatar } from '@cointracker/styleguide/src/LoggedIn/Avatar';
import {
  ArrowLeft,
  ArrowsLeftRight,
  ChartBar,
  ChartLine,
  ChartPie,
  Eye,
  File,
  FileX,
  Gear,
  Heartbeat,
  Money,
  QuestionMark,
  Robot,
  Sparkle,
  UserPlus,
  Wallet,
} from '@phosphor-icons/react';
import { matchPath } from 'react-router-dom';
import { AuthenticatedUser, User } from 'src/app/user/context';
import dayjs from 'src/app/utils/dayjsConfig';
import { URLS } from 'src/common/urls';

export const getTaxPageUrl = (taxYearToUse: number) => {
  return `${URLS.TAX_PAGE}/${taxYearToUse || dayjs().format('YYYY')}`;
};

export function isAuthenticatedUser(user: User): user is AuthenticatedUser {
  return user.hasFetched && user.isAuthenticated;
}
interface getNavigationItemsProps {
  taxPageUrl: string;
  currentPathName: string;
  isMobileNavigation?: boolean;
}
export const getNavigationItems = ({
  taxPageUrl,
  currentPathName,
}: getNavigationItemsProps) => [
  {
    slug: URLS.HOME_PAGE,
    title: 'Portfolio',
    icon: (props) => (
      <ChartPie size={18} className="h-18 min-h-18 w-18 min-w-18" {...props} />
    ),
    isActive: getIsActiveStateForPath(URLS.HOME_PAGE, currentPathName),
  },
  {
    slug: URLS.RECONCILIATION,
    title: 'Account health',
    isActive: getIsActiveStateForPath(URLS.RECONCILIATION, currentPathName),
    icon: (props) => (
      <Heartbeat size={18} className="h-18 min-h-18 w-18 min-w-18" {...props} />
    ),
  },
  {
    slug: URLS.WALLETS_PAGE,
    title: 'Wallets',
    isActive: getIsActiveStateForPath(URLS.WALLETS_PAGE, currentPathName),
    icon: (props) => (
      <Wallet size={18} className="h-18 min-h-18 w-18 min-w-18" {...props} />
    ),
  },
  {
    slug: URLS.TRANSACTIONS_PAGE,
    title: 'Transactions',
    isActive: getIsActiveStateForPath(URLS.TRANSACTIONS_PAGE, currentPathName),
    icon: (props) => (
      <ArrowsLeftRight
        size={18}
        className="h-18 min-h-18 w-18 min-w-18"
        {...props}
      />
    ),
  },
  {
    slug: URLS.SPAM,
    title: 'Spam',
    isActive: getIsActiveStateForPath(URLS.SPAM, currentPathName),
    icon: (props) => (
      <FileX size={18} className="h-18 min-h-18 w-18 min-w-18" {...props} />
    ),
  },
  {
    slug: 'break',
    title: 'first-break-line',
    isActive: false,
    icon: null,
  },
  {
    slug: taxPageUrl,
    title: 'Taxes',
    icon: (props) => (
      <File size={18} className="h-18 min-h-18 w-18 min-w-18" {...props} />
    ),
    isActive: getIsActiveStateForPath(URLS.TAX_PAGE, currentPathName),
  },
  {
    slug: URLS.TAX_LOSS_HARVEST,
    title: 'Tax loss harvesting',
    isActive: getIsActiveStateForPath(URLS.TAX_LOSS_HARVEST, currentPathName),
    icon: (props) => (
      <Money size={18} className="h-18 min-h-18 w-18 min-w-18" {...props} />
    ),
  },
  {
    slug: 'break',
    title: 'second-break-line',
    isActive: false,
    icon: null,
  },
  {
    slug: URLS.PERFORMANCE_PAGE,
    title: 'Performance',
    icon: (props) => (
      <ChartBar size={18} className="h-18 min-h-18 w-18 min-w-18" {...props} />
    ),
    isActive: getIsActiveStateForPath(URLS.PERFORMANCE_PAGE, currentPathName),
  },
  {
    slug: URLS.PRICES_PAGE,
    title: 'Prices',
    isActive: getIsActiveStateForPath(URLS.PRICES_PAGE, currentPathName),
    icon: (props) => (
      <ChartLine size={18} className="h-18 min-h-18 w-18 min-w-18" {...props} />
    ),
  },
];

export const getAccountNavItem = (
  userEmail: string,
  userIsAdmin: boolean,
  onNavigateToSupport: () => void,
  onClickChatWithSupport: () => void,
  onClickViewAs: () => void,
  onLogout: () => void,
  currentPathName: string,
) => {
  return {
    slug: null,
    title: 'Account',
    isActive:
      getIsActiveStateForPath(URLS.EDIT_USER_PROFILE, currentPathName) ||
      getIsActiveStateForPath(
        URLS.UNIFIED_SUBSCRIPTION_PLANS_PAGE,
        currentPathName,
      ) ||
      getIsActiveStateForPath(URLS.VIEW_TAX_PRO_CLIENTS, currentPathName),
    isUserIcon: true,
    icon: (props) => <Avatar variant="color" size="xs" {...props} />,
    subItems: [
      {
        slug: URLS.EDIT_USER_PROFILE,
        title: 'Settings',
        isActive: getIsActiveStateForPath(
          URLS.EDIT_USER_PROFILE,
          currentPathName,
        ),
        icon: () => <Gear size={18} className="h-18 min-h-18 w-18 min-w-18" />,
      },
      {
        slug: URLS.UNIFIED_SUBSCRIPTION_PLANS_PAGE,
        title: 'Subscription plans',
        isActive: getIsActiveStateForPath(
          URLS.UNIFIED_SUBSCRIPTION_PLANS_PAGE,
          currentPathName,
        ),
        icon: () => (
          <Sparkle size={18} className="h-18 min-h-18 w-18 min-w-18" />
        ),
      },
      {
        slug: URLS.VIEW_TAX_PRO_CLIENTS,
        title: 'Add a tax professional',
        isActive: getIsActiveStateForPath(
          URLS.VIEW_TAX_PRO_CLIENTS,
          currentPathName,
        ),
        icon: () => (
          <UserPlus size={18} className="h-18 min-h-18 w-18 min-w-18" />
        ),
      },
      {
        slug: null,
        title: 'Help center',
        isActive: false,
        icon: () => (
          <QuestionMark size={18} className="h-18 min-h-18 w-18 min-w-18" />
        ),
        action: onNavigateToSupport,
      },
      {
        slug: null,
        title: 'Chat with support',
        isActive: false,
        icon: () => <Robot size={18} className="h-18 min-h-18 w-18 min-w-18" />,
        action: onClickChatWithSupport,
      },
      {
        slug: null,
        title: 'View as',
        isActive: false,
        icon: () => <Eye size={18} className="h-18 min-h-18 w-18 min-w-18" />,
        hide: !userIsAdmin,
        action: onClickViewAs,
      },
      {
        slug: null,
        title: 'Sign out',
        subTitle: userEmail,
        isActive: false,
        icon: () => (
          <ArrowLeft size={18} className="h-18 min-h-18 w-18 min-w-18" />
        ),
        action: onLogout,
      },
    ],
  };
};

export const getIsActiveStateForPath = (
  slug: string,
  currentPathName: string,
) => {
  switch (slug) {
    case URLS.HOME_PAGE:
      // mobile navigation components deviate from the desktop ones for the time being and we need to make sure different
      // items are active at different components
      return Boolean(matchPath(URLS.HOME_PAGE, currentPathName));
    case URLS.WALLETS_PAGE:
      return Boolean(matchPath(`${URLS.WALLETS_PAGE}/*`, currentPathName));
    case URLS.TRANSACTIONS_PAGE:
      return Boolean(matchPath(`${URLS.TRANSACTIONS_PAGE}/*`, currentPathName));
    case URLS.RECONCILIATION:
      return Boolean(matchPath(`${URLS.RECONCILIATION}/*`, currentPathName));
    case URLS.SPAM:
      return Boolean(matchPath(`${URLS.SPAM}/*`, currentPathName));
    case URLS.TAX_PAGE:
      return Boolean(matchPath(`${URLS.TAX_PAGE}/*`, currentPathName));
    case URLS.TAX_LOSS_HARVEST:
      return Boolean(matchPath(URLS.TAX_LOSS_HARVEST, currentPathName));
    case URLS.PERFORMANCE_PAGE:
      return Boolean(matchPath(URLS.PERFORMANCE_PAGE, currentPathName));
    case URLS.PRICES_PAGE:
      return Boolean(matchPath(`${URLS.PRICES_PAGE}/*`, currentPathName));
    case URLS.EDIT_USER_PROFILE:
      return Boolean(matchPath(URLS.EDIT_USER_PROFILE, currentPathName));
    case URLS.UNIFIED_SUBSCRIPTIONS_PLANS_CHECKOUT_PAGE:
      return Boolean(matchPath(URLS.EDIT_USER_PROFILE, currentPathName));
    case URLS.UNIFIED_SUBSCRIPTION_PLANS_PAGE:
      return Boolean(
        matchPath(URLS.UNIFIED_SUBSCRIPTION_PLANS_PAGE, currentPathName),
      );
    case URLS.VIEW_TAX_PRO_CLIENTS:
      return Boolean(matchPath(URLS.VIEW_TAX_PRO_CLIENTS, currentPathName));
    default:
      return false;
  }
};
