import { Body, Heading } from '@cointracker/styleguide/src/LoggedIn';
import { ImageBroken } from '@phosphor-icons/react';
import { ThemeProvider } from 'styled-components';
import { lightTheme } from '../../components/colors/theme';

interface PageNotFoundProps {
  userIsAuthenticated?: boolean;
}

export function PageNotFound({ userIsAuthenticated }: PageNotFoundProps) {
  return (
    <div className="flex min-h-full flex-1 flex-col place-items-center justify-center">
      <div className="m-30 flex h-[180px] w-[180px] place-items-center justify-center rounded-full bg-background-card-highlight">
        <ImageBroken className="text-text-primary" size={64} />
      </div>
      <div className="flex flex-col place-items-center gap-8 py-32 text-center">
        <Heading variant="h5" className="text-text-secondary">
          Page not found
        </Heading>
        <Body variant="body3" className="text-text-secondary">
          {`The page you are looking for has been moved or doesn't exist anymore.`}
        </Body>
      </div>
      {userIsAuthenticated && (
        <a href="/">
          {userIsAuthenticated ? 'Return to dashboard' : 'Return to homepage'}
        </a>
      )}
    </div>
  );
}

export function PageNotFoundSPAWrapper(props: PageNotFoundProps) {
  return (
    <ThemeProvider theme={lightTheme}>
      <PageNotFound {...props} />
    </ThemeProvider>
  );
}
