import { createGlobalStyle } from 'styled-components';

export const CookieGlobalStyles = createGlobalStyle`
  #cc-main .cm {
    max-width: 660px !important;
  }
  #cc-main .cc__link, #cc-main a{
    font-weight: normal !important;
    color: var(--primary-650) !important;
  }
  #cc-main .cc__link:hover, #cc-main a:hover{
    color: var(--primary-800) !important;
  }
`;
