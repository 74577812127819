import { AnalyticsClient, CTClient } from '@cointracker/analytics';
import React, { useCallback, useEffect, useState } from 'react';
import { useBeforeUnload } from 'react-router-dom';
import { User } from 'src/app/user/context';
import { AnalyticsContext } from './context';

interface Props {
  writeKey: string;
  mixpanelToken: string;
  children: React.ReactNode;
  user: User;
}

const cdnUrl = process.env.SEGMENT_CDN_HOST;
const isEnvProduction = process.env.NODE_ENV === 'production';
/**
 * Provides the analytics library throughout the application. Async loads the library
 * only when the user data is available in the application.
 */
export const AnalyticsProvider = ({
  children,
  writeKey,
  mixpanelToken,
  user,
}: Props) => {
  const [analytics] = useState(new AnalyticsClient());
  useEffect(() => {
    // Only load analytics once user data is available
    if (user?.hasFetched && user.isAuthenticated) {
      analytics.load(
        writeKey,
        mixpanelToken,
        {
          publicId: user.publicId,
          isAuthenticated: true,
          email: user.email,
        },
        CTClient.webApp,
        cdnUrl,
        isEnvProduction,
      );
    } else if (user?.hasFetched && !user.isAuthenticated) {
      analytics.init(
        writeKey,
        mixpanelToken,
        { isAuthenticated: false },
        CTClient.webApp,
        cdnUrl,
      );
    }
  }, [writeKey, mixpanelToken, user, analytics]);

  // Before the user navigates away, forceFlush any events that might be in the buffer
  useBeforeUnload(
    useCallback(() => {
      if (analytics) analytics?.forceFlush();
    }, [analytics]),
  );

  return (
    <AnalyticsContext.Provider value={{ analytics }}>
      {children}
    </AnalyticsContext.Provider>
  );
};
