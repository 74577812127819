import { Helmet as ReactHelmet } from 'react-helmet-async';
import { useThemeDetector } from 'src/hooks/useThemeDetector';
import { Head, HeadProps } from './Head';

export default function Helmet(props: HeadProps) {
  const userPrefersDarkTheme = useThemeDetector();
  return (
    <ReactHelmet>
      {Head({ ...props, isDarkMode: userPrefersDarkTheme })}
    </ReactHelmet>
  );
}
