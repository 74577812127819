import styled from 'styled-components';
export const AppBody = styled.div`
  background: ${({ theme }) => theme.grey.light};
  width: 100%;
`;

export const ViewportContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
