import SOCITypeII from '@cointracker/assets/general/soc-1-type-2.svg?react';
import SOCIITypeII from '@cointracker/assets/general/soc-2-type-2.svg?react';
import { REBRAND_URLS } from '@cointracker/ui';
import classNames from 'classnames';
import { Button } from '../Button';
import { NavLink } from '../NavLink';
import { Tag } from '../Tag';
import AppStoreIcon from '../icons/app-store.svg?react';
import CoinTrackerTextLogo from '../icons/cointracker-text-logo.svg?react';
import PlayStoreIcon from '../icons/play-store.svg?react';
import Facebook from '../icons/social/facebook-monochrome.svg?react';
import LinkedIn from '../icons/social/linkedin-monochrome.svg?react';
import Reddit from '../icons/social/reddit-monochrome.svg?react';
import X from '../icons/social/x-monochrome.svg?react';
import YouTube from '../icons/social/youtube-monochrome.svg?react';
import { type ChildAndClassNameProps } from '../types';
import { Body1, Body3, H5 } from '../typography';
import {
  appStoreButton,
  appStoreButtons,
  appStoreGroupedContent,
  appStoreSection,
  bottomNavigationList,
  bottomSection,
  bottomSectionInner,
  bottomSocialList,
  coinTrackerTextLogo,
  copyright,
  downloadSubheading,
  footer,
  footerGrid,
  navigationList,
  navigationListItem,
  navigationSection,
  socBadges,
  socialLink,
  topSection,
  topSectionTop,
} from './Footer.css';

const FooterGrid = (props: ChildAndClassNameProps) => {
  const { children, className, ...rest } = props;

  return (
    <div className={classNames(className, footerGrid)} {...rest}>
      {children}
    </div>
  );
};

FooterGrid.displayName = 'FooterGrid';

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className={footer}>
      <section className={topSection}>
        <FooterGrid className={topSectionTop}>
          <div className={navigationSection}>
            <ul className={navigationList}>
              <li className={navigationListItem}>
                <NavLink href={REBRAND_URLS.ABOUT} variant="primary">
                  <Body1>About</Body1>
                </NavLink>
              </li>
              <li className={navigationListItem}>
                <NavLink href={REBRAND_URLS.CAREERS} variant="primary">
                  <Body1>Careers</Body1>
                </NavLink>
                <Tag secondary caps>
                  Hiring
                </Tag>
              </li>
              <li className={navigationListItem}>
                <NavLink href={REBRAND_URLS.BRAND} variant="primary">
                  <Body1>Brand</Body1>
                </NavLink>
              </li>
              <li className={navigationListItem}>
                <NavLink href={REBRAND_URLS.GLOSSARY} variant="primary">
                  <Body1>Crypto glossary</Body1>
                </NavLink>
              </li>
              <li className={navigationListItem}>
                <NavLink href={REBRAND_URLS.SUPPORT} variant="primary">
                  <Body1>Support</Body1>
                </NavLink>
              </li>
              <li className={navigationListItem}>
                <NavLink href={REBRAND_URLS.STATUS} variant="primary">
                  <Body1>Status</Body1>
                </NavLink>
              </li>
              <li className={navigationListItem}>
                <NavLink href={REBRAND_URLS.SECURITY} variant="primary">
                  <Body1>Security</Body1>
                </NavLink>
              </li>
            </ul>
            <div className={socBadges}>
              <SOCITypeII />
              <SOCIITypeII />
            </div>
          </div>
          <div className={appStoreSection}>
            <div className={appStoreGroupedContent}>
              <H5>Download our app</H5>
              <Body3 className={downloadSubheading}>
                View your return on investment, your unified transaction
                history, wallet balances, and much more on the go.
              </Body3>
            </div>
            <div className={appStoreButtons}>
              <Button
                href={REBRAND_URLS.APPLE_APP_STORE}
                variant="secondary"
                hideInitialIcon
                className={appStoreButton}
                icon={<AppStoreIcon />}
              >
                App store
              </Button>
              <Button
                href={REBRAND_URLS.GOOGLE_PLAY_STORE}
                variant="secondary"
                hideInitialIcon
                className={appStoreButton}
                icon={<PlayStoreIcon />}
              >
                Play store
              </Button>
            </div>
          </div>
        </FooterGrid>
        <FooterGrid>
          <CoinTrackerTextLogo className={coinTrackerTextLogo} />
        </FooterGrid>
      </section>
      <section className={bottomSection}>
        <FooterGrid className={bottomSectionInner}>
          <ul className={bottomNavigationList}>
            <li>
              <Body3 className={copyright}>© CoinTracker {currentYear}</Body3>
            </li>
            <li>
              <NavLink href={REBRAND_URLS.PRIVACY} variant="secondary">
                <Body3>Privacy Policy</Body3>
              </NavLink>
            </li>
            <li>
              <NavLink href={REBRAND_URLS.TERMS} variant="secondary">
                <Body3>Terms</Body3>
              </NavLink>
            </li>
            <li>
              <NavLink href={REBRAND_URLS.DISCLAIMER} variant="secondary">
                <Body3>Disclaimer</Body3>
              </NavLink>
            </li>
          </ul>
          <ul className={bottomSocialList}>
            <li>
              <NavLink
                href={REBRAND_URLS.SOCIAL_MEDIA.X}
                variant="secondary"
                socialLink
              >
                <X className={socialLink} />
              </NavLink>
            </li>
            <li>
              <NavLink
                href={REBRAND_URLS.SOCIAL_MEDIA.REDDIT}
                variant="secondary"
                socialLink
              >
                <Reddit className={socialLink} />
              </NavLink>
            </li>
            <li>
              <NavLink
                href={REBRAND_URLS.SOCIAL_MEDIA.LINKEDIN}
                variant="secondary"
                socialLink
              >
                <LinkedIn className={socialLink} />
              </NavLink>
            </li>
            <li>
              <NavLink
                href={REBRAND_URLS.SOCIAL_MEDIA.FACEBOOK}
                variant="secondary"
                socialLink
              >
                <Facebook className={socialLink} />
              </NavLink>
            </li>
            <li>
              <NavLink
                href={REBRAND_URLS.SOCIAL_MEDIA.YOUTUBE}
                variant="secondary"
                socialLink
              >
                <YouTube className={socialLink} />
              </NavLink>
            </li>
          </ul>
        </FooterGrid>
      </section>
    </footer>
  );
};
