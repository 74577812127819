import { cva } from 'class-variance-authority';
// TODO: update border radius with semantic value when added
export const buttonVariants = cva(
  'rounded-8 inline-flex cursor-pointer items-center justify-center gap-2 disabled:cursor-default',
  {
    variants: {
      variant: {
        primary:
          'bg-background-inverse text-text-primary-foreground-inverse hover:bg-background-primary-hover active:bg-background-primary-pressed disabled:bg-background-primary-disabled disabled:text-text-primary-foreground-inverse-disabled',
        line: 'text-text-primary-foreground hover:text-text-primary-foreground-hover active:text-text-primary-foreground-pressed border-line-secondary hover:border-line-secondary-hover active:border-line-secondary-pressed disabled:border-line-secondary-disabled disabled:text-text-primary-foreground-disabled border border-solid bg-transparent',
        negative:
          'bg-background-negative text-text-negative-foreground hover:bg-background-negative-hover active:bg-background-negative-pressed disabled:bg-background-negative-disabled disabled:text-text-negative-foreground-disabled',
        highEmphasis:
          'bg-background-high-emphasis text-text-primary-foreground-inverse hover:bg-background-high-emphasis-hover active:bg-background-high-emphasis-pressed disabled:bg-background-high-emphasis-disabled',
      },
      size: {
        small: 'h-[42px] px-16 py-8',
        medium: 'h-[46px] px-24 py-[15px]',
        // TODO: update py with a spacing rule
        large: 'h-[50px] px-24 py-[15px]',
      },
      hover: { true: '' },
      pressed: { true: '' },
      transparent: { true: '' },
      fluid: {
        true: 'w-full',
        false: 'w-fit',
      },
      loading: { true: '' },
    },
    compoundVariants: [
      {
        variant: 'primary',
        hover: true,
        className: 'bg-background-primary-hover',
      },
      {
        variant: 'primary',
        pressed: true,
        className: 'bg-background-primary-pressed',
      },
      {
        variant: 'primary',
        transparent: true,
        className:
          'text-text-primary-foreground hover:text-text-primary-foreground-hover active:text-text-primary-foreground-pressed disabled:text-text-primary-foreground-disabled bg-transparent hover:bg-transparent active:bg-transparent disabled:bg-transparent',
      },
      // TODO: These classes are only needed for storybook but they will be injected in the production source as well.
      //       We should optimise by not including them for storybook or by using the storybook actions to remove the need for them.
      {
        variant: 'primary',
        hover: true,
        transparent: true,
        className: 'text-text-primary-foreground-hover bg-transparent',
      },
      {
        variant: 'primary',
        pressed: true,
        transparent: true,
        className: 'text-text-primary-foreground-pressed bg-transparent',
      },

      {
        variant: 'line',
        hover: true,
        className:
          'text-text-primary-foreground-hover border-line-secondary-hover',
      },
      {
        variant: 'line',
        pressed: true,
        className:
          'text-text-primary-foreground-pressed border-line-secondary-pressed',
      },
      {
        variant: 'line',
        transparent: true,
        className:
          'text-text-primary-foreground hover:text-background-secondary-hover active:text-text-primary-foreground-pressed disabled:text-text-primary-foreground-disabled border-none',
      },
      {
        variant: 'line',
        hover: true,
        transparent: true,
        className: 'text-background-secondary-hover',
      },
      {
        variant: 'line',
        pressed: true,
        transparent: true,
        className: 'text-text-primary-foreground-pressed',
      },

      {
        variant: 'negative',
        hover: true,
        className: 'bg-background-negative-hover',
      },
      {
        variant: 'negative',
        pressed: true,
        className: 'bg-background-negative-pressed',
      },
      {
        variant: 'negative',
        transparent: true,
        className:
          'text-text-negative hover:text-text-negative-foreground-hover active:text-text-negative-foreground-pressed disabled:text-background-negative-disabled bg-transparent hover:bg-transparent active:bg-transparent disabled:bg-transparent',
      },
      {
        variant: 'negative',
        hover: true,
        transparent: true,
        className: 'text-text-negative-foreground-hover bg-transparent',
      },
      {
        variant: 'negative',
        pressed: true,
        transparent: true,
        className: 'text-text-negative-foreground-pressed bg-transparent',
      },
    ],
    defaultVariants: {
      variant: 'primary',
      size: 'large',
      pressed: false,
      fluid: false,
    },
  },
);

export const buttonSpinnerVariants = cva('animate-spin', {
  variants: {
    variant: {
      primary:
        '[&_circle:first-of-type]:stroke-line-primary-pressed [&_circle:last-of-type]:stroke-text-primary-foreground-inverse',
      line: '[&_circle:first-of-type]:stroke-accent-subtle-grey [&_circle:last-of-type]:stroke-icon-primary-foreground',
      negative:
        '[&_circle:first-of-type]:stroke-background-negative-wash [&_circle:last-of-type]:stroke-accent-bold-red',
      highEmphasis:
        '[&_circle:first-of-type]:stroke-background-high-emphasis-hover [&_circle:last-of-type]:stroke-text-primary-foreground-inverse',
    },
    transparent: { true: '' },
  },
  compoundVariants: [
    {
      variant: 'primary',
      transparent: true,
      className:
        '[&_circle:first-of-type]:stroke-accent-subtle-grey [&_circle:last-of-type]:stroke-icon-primary-foreground',
    },
    {
      variant: 'line',
      transparent: true,
      className:
        '[&_circle:first-of-type]:stroke-accent-subtle-grey [&_circle:last-of-type]:stroke-icon-primary-foreground',
    },
    {
      variant: 'negative',
      transparent: true,
      className:
        '[&_circle:first-of-type]:stroke-background-negative-wash [&_circle:last-of-type]:stroke-accent-bold-red',
    },
  ],
  defaultVariants: {
    variant: 'primary',
  },
});
