import { trackSignUpButtonClicked } from '@cointracker/landing-app/src/common/analytics';
import classNames from 'classnames';
import { forwardRef, useEffect, useState, type Ref } from 'react';
import { Button } from '../Button';
import { Grid } from '../Grid';
import { Body1 } from '../typography';
import { inner, root, styledButton, text } from './StickyBottomCTA.css';

export interface StickyBottomCTAProps {
  className?: string;
  message: string;
  showAtScroll?: number;
  signupHref: string;
  page: string;
}

export const StickyBottomCTA = forwardRef(
  (
    {
      className,
      message,
      showAtScroll,
      signupHref,
      page,
      ...rest
    }: StickyBottomCTAProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const [isVisible, setIsVisible] = useState(!showAtScroll);

    useEffect(() => {
      if (!showAtScroll) return;

      let ticking = false;

      const handleScroll = () => {
        if (ticking) return;

        setTimeout(() => {
          const docHeight = document.documentElement.scrollHeight;
          const winHeight = window.innerHeight;

          const scrollableHeight = docHeight - winHeight;

          if (scrollableHeight <= 0) {
            setIsVisible(true);
          } else {
            const scrolledPercentage = window.scrollY / window.innerHeight;
            setIsVisible(scrolledPercentage >= showAtScroll);
          }

          ticking = false;
        }, 100);
        ticking = true;
      };

      handleScroll();

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [showAtScroll]);

    return (
      <div
        className={classNames(className, root({ hidden: !isVisible }))}
        ref={ref}
        {...rest}
      >
        <Grid className={inner}>
          <Body1 className={text} weight="bold">
            {message}
          </Body1>
          <a
            href={signupHref}
            onClick={() =>
              trackSignUpButtonClicked(
                'Blog page',
                'sticky-bottom-cta',
                'Get CoinTracker',
                page,
              )
            }
          >
            <Button className={styledButton} variant="secondary">
              Get CoinTracker
            </Button>
          </a>
        </Grid>
      </div>
    );
  },
);

StickyBottomCTA.displayName = 'StickyBottomCTA';
