import { useApolloClient } from '@apollo/client';
import { useContext } from 'react';
import { CustomWallet, Exchange, Wallet } from 'src/types/graphql-types';
import { GetWallets } from '../../queries/getWallets.graphql';

import { Button, ButtonProps } from '@cointracker/styleguide/src/LoggedIn';
import { Plus } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import { AddWalletContext } from './AddWalletModal/context';
import { getNavigationUrlByWalletOrExchangeType } from './AddWalletModal/utils';

export const AddWalletButton = ({
  variant,
  fluid,
}: {
  variant?: ButtonProps['variant'];
  fluid?: boolean;
}) => {
  const { onOpenAddWalletModal, loading } = useContext(AddWalletContext);
  const navigate = useNavigate();
  const client = useApolloClient();
  const onSuccess = (
    createdWalletOrExchange: Exchange | Wallet | CustomWallet,
  ) => {
    client.refetchQueries({
      include: [GetWallets],
    });
    navigate(getNavigationUrlByWalletOrExchangeType(createdWalletOrExchange));
  };

  return (
    <Button
      onClick={() => onOpenAddWalletModal({ onSuccess })}
      disabled={loading}
      variant={variant ?? 'line'}
      size="medium"
      fluid={fluid}
    >
      <Plus size={18} weight="bold" />
      Add wallet
    </Button>
  );
};

export default AddWalletButton;
