import { createContext } from 'react';
type SidebarContext = {
  state: 'expanded' | 'collapsed';
  open: boolean;
  setOpen: (open: boolean) => void;
  openMobile: boolean;
  setOpenMobile: (open: boolean) => void;
  isMobile: boolean;
  toggleSidebar: () => void;
  isStatusSheetOpen: boolean;
  setIsStatusSheetOpen: (open: boolean) => void;
  toggleStatusSheet: () => void;
};

const SidebarContext = createContext<SidebarContext | null>(null);

export { SidebarContext };
