import PricingCardCheck from '@cointracker/styleguide/src/icons/check-pricing.svg?react';
import PricingCardTransaction from '@cointracker/styleguide/src/icons/transaction.svg?react';
import PricingCardNoTransaction from '@cointracker/styleguide/src/icons/x-page.svg?react';
import {
  forwardRef,
  type HTMLAttributes,
  type ReactNode,
  type Ref,
} from 'react';

import { Warning, X } from '@phosphor-icons/react';
import {
  Body2,
  Body3,
  Body4,
  Body5,
  H4,
  Heading,
  Tags,
  type TagsProps,
} from '../LoggedIn';
import { cn } from '../utils';

interface PricingCardProps extends HTMLAttributes<HTMLDivElement> {
  planStyle?: 'free' | 'base' | 'prime' | 'ultra';
  highlight?: boolean;
  withBorder?: boolean;
}
export const PricingCard = forwardRef(
  (
    { planStyle, highlight, withBorder, className, ...props }: PricingCardProps,
    ref: Ref<HTMLElement>,
  ) => {
    return (
      <article
        ref={ref}
        {...props}
        data-highlighted={highlight}
        data-plan-style={planStyle}
        className={cn(
          'rounded-16 bg-background-card border-line-card group flex flex-col gap-16 p-16 lg:min-h-[500px]',
          'lg:ease-simple lg:transition-colors lg:duration-500',
          {
            border: withBorder,
            'lg:hover:bg-lilac-30': planStyle === 'free',
            'bg-lilac-30 lg:bg-background-card':
              planStyle === 'free' && highlight,
            'lg:hover:bg-background-expressive lg:hover:text-text-emphasis-hover-consistent-light':
              planStyle === 'base',
            'bg-background-expressive lg:bg-background-card':
              planStyle === 'base' && highlight,
            'lg:hover:bg-accent-bold-blue lg:hover:text-text-primary-foreground-consistent-alt-hover':
              planStyle === 'prime',
            'bg-accent-bold-blue text-text-primary-foreground-consistent-alt-hover lg:bg-background-card lg:text-text-primary':
              planStyle === 'prime' && highlight,
            'lg:hover:bg-accent-bold-teal lg:hover:text-text-primary-foreground-consistent-alt-hover ease-simple transition-colors duration-500':
              planStyle === 'ultra',
            'bg-accent-bold-teal text-text-primary-foreground-consistent-alt-hover lg:bg-background-card lg:text-text-primary':
              planStyle === 'ultra' && highlight,
          },
          className,
        )}
      ></article>
    );
  },
);

export const PricingCardHeader = (props: HTMLAttributes<HTMLDivElement>) => (
  <header
    {...props}
    className={cn(
      'flex flex-col items-start justify-center gap-16',
      props.className,
    )}
  />
);

interface PricingCardTitleProps {
  tagline?: ReactNode;
  children: ReactNode;
  tag?: string;
  tagColor?: TagsProps['color'];
  className?: string;
}
export const PricingCardTitle = ({
  tagline,
  children,
  tag,
  tagColor = 'positive',
  className,
}: PricingCardTitleProps) => (
  <div
    className={cn(
      'flex w-full flex-row flex-wrap items-start justify-between',
      className,
    )}
  >
    <div className="flex flex-col flex-wrap gap-2">
      <Body5
        className={cn(
          'text-text-primary',
          'lg:ease-simple lg:transition-colors lg:duration-500',
          'lg:group-data-[plan-style=base]:group-hover:text-text-emphasis-hover-consistent-light',
          'lg:group-data-[plan-style=prime]:group-hover:text-text-primary-foreground-consistent-alt-hover',
          'group-data-[plan-style=prime]:group-data-[highlighted=true]:text-text-primary-foreground-consistent-alt-hover',
          'lg:group-data-[plan-style=ultra]:group-hover:text-text-primary-foreground-consistent-alt-hover',
          'group-data-[plan-style=ultra]:group-data-[highlighted=true]:text-text-primary-foreground-consistent-alt-hover',
          'lg:group-data-[plan-style=ultra]:group-data-[highlighted=true]:text-text-primary',
          'lg:group-data-[plan-style=prime]:group-data-[highlighted=true]:text-text-primary',
        )}
      >
        {tagline ? tagline : null}
      </Body5>
      <Heading className="text-inherit" variant="h5">
        {children}
      </Heading>
    </div>
    {tag ? (
      <div className="grow-1 flex flex-row items-center gap-4">
        <Tags
          color={tagColor}
          filled
          condensed
          className={cn(
            'lg:ease-simple lg:transition-colors lg:duration-500',
            'lg:group-data-[plan-style=base]:group-hover:text-text-emphasis-hover-consistent-light',
            'lg:group-data-[plan-style=ultra]:group-hover:bg-teal-70 lg:group-data-[plan-style=ultra]:group-hover:border-transparent',
            'lg:group-data-[plan-style=ultra]:group-hover:text-text-primary-foreground-consistent-alt-hover',
            'lg:group-data-[plan-style=prime]:group-hover:bg-blue-70 lg:group-data-[plan-style=prime]:group-hover:border-transparent',
            'lg:group-data-[plan-style=prime]:group-hover:text-text-primary-foreground-consistent-alt-hover',
            'lg:group-data-[plan-style=base]:group-hover:bg-line-50 lg:group-data-[plan-style=base]:group-hover:border-transparent',
            'group-data-[plan-style=prime]:group-data-[highlighted=true]:!bg-blue-70 lg:group-data-[plan-style=prime]:group-data-[highlighted=true]:!bg-blue-60',
            'group-data-[plan-style=ultra]:group-data-[highlighted=true]:!bg-teal-70 lg:group-data-[plan-style=ultra]:group-data-[highlighted=true]:!bg-teal-60',
          )}
        >
          {tag}
        </Tags>
      </div>
    ) : null}
  </div>
);

PricingCard.displayName = 'PricingCard';

interface PricingCardPriceTextProps {
  price?: ReactNode;
  period?: ReactNode;
  discountText?: ReactNode;
  className?: string;
}
export const PricingCardPriceText = ({
  price,
  period,
  discountText,
  className,
}: PricingCardPriceTextProps) => (
  <div className={cn('flex flex-col', className)}>
    <div className="flex items-center gap-4">
      {price && <H4>{price}</H4>}
      {period && <Body3>{period}</Body3>}
    </div>
    {discountText && (
      <div>
        <Body2
          className={cn(
            'text-text-secondary line-through',
            'lg:ease-simple lg:transition-colors lg:duration-500',
            'lg:group-data-[plan-style=base]:group-hover:text-text-emphasis-hover-consistent-light',
            'lg:group-data-[plan-style=prime]:group-hover:text-text-primary-foreground-consistent-alt-hover',
            'lg:group-data-[plan-style=ultra]:group-hover:text-text-primary-foreground-consistent-alt-hover',
          )}
        >
          {discountText}
        </Body2>
      </div>
    )}
  </div>
);

PricingCardPriceText.displayName = 'PricingCardPriceText';

interface PricingCardBodyProps {
  children: ReactNode;
  className?: string;
}
export const PricingCardBody = ({
  children,
  className,
}: PricingCardBodyProps) => (
  <div className={cn('flex flex-col', className)}>{children}</div>
);

interface PricingCardBodyListProps {
  children: ReactNode;
}
export const PricingCardBodyList = ({ children }: PricingCardBodyListProps) => (
  <ul
    className={cn(
      // only apply the translate-y transition to the card if it has the pricing footer
      'group-[&:has(.pricing-card-footer)]:lg:translate-y-[62px] group-[&:has(.pricing-card-footer)]:lg:group-hover:translate-y-0',
      'flex h-full flex-col justify-end gap-4',
      'lg:ease-simple lg:transition-transform lg:duration-500',
    )}
  >
    {children}
  </ul>
);

PricingCardBodyList.displayName = 'PricingCardBodyList';

interface PricingCardListItemProps {
  icon?:
    | 'check'
    | 'no-transaction'
    | 'transaction'
    | 'warning'
    | 'x'
    | 'no-icon';
  children: ReactNode;
  extraPadding?: boolean;
  className?: string;
}

export const PricingCardListItem = ({
  icon = 'check',
  children,
  extraPadding,
  className,
}: PricingCardListItemProps) => (
  <li
    className={cn(
      'flex items-center gap-8',
      { 'mt-24': extraPadding },
      className,
    )}
  >
    <div>
      {(icon === 'check' || !icon) && <PricingCardCheck height="17px" />}
      {icon === 'no-transaction' && <PricingCardNoTransaction height="17px" />}
      {icon === 'transaction' && <PricingCardTransaction height="17px" />}
      {icon === 'warning' && (
        <Warning size={17} className="text-text-negative" />
      )}
      {icon === 'x' && (
        <X weight="bold" size={17} className="text-text-negative" />
      )}
      {icon === 'no-icon' && <EmptyIcon />}
    </div>
    <Body4>{children}</Body4>
  </li>
);

interface PricingCardFooterProps {
  children: ReactNode;
  className?: string;
}
export const PricingCardFooter = ({
  children,
  className,
}: PricingCardFooterProps) => (
  <footer
    className={cn(
      'pricing-card-footer',
      'lg:ease-simple lg:opacity-0 lg:transition-opacity lg:duration-500 lg:group-hover:!opacity-100',
      className,
    )}
  >
    {children}
  </footer>
);

const EmptyIcon = () => <div className="h-[17px] w-[17px]" />;

interface PricingCardRowProps {
  children: ReactNode;
  className?: string;
}
export const PricingCardRow = ({
  children,
  className,
}: PricingCardRowProps) => (
  <div
    className={cn(
      'grid w-full grid-cols-1 gap-16 md:grid-cols-2 lg:grid-cols-[repeat(auto-fit,minmax(200px,1fr))] lg:gap-24',
      className,
    )}
  >
    {children}
  </div>
);
