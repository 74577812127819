import Input from 'components/Input';
import { FormGroup, Label } from 'components/Input/styles';
import { URLS } from 'src/common/urls';
import {
  RegularPrimary,
  RegularSecondary,
} from 'src/components/buttons/regular';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'src/components/Modal';

interface ViewAsModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export default function ViewAsModal({
  isOpen = false,
  onClose = () => {},
}: ViewAsModalProps) {
  return (
    <>
      <Modal open={isOpen} onOpenChange={onClose}>
        <ModalHeader title="Select user to view as" />
        <form action={URLS.VIEW_AS} method="POST">
          <ModalBody>
            <FormGroup>
              <Label as="label" htmlFor="user-email">
                User:
              </Label>
              <Input type="text" name="user-email" id="user-email" />
            </FormGroup>
            <ModalFooter>
              <RegularSecondary onClick={onClose} type="button">
                Cancel
              </RegularSecondary>
              <RegularPrimary type="submit">Submit</RegularPrimary>
            </ModalFooter>
          </ModalBody>
        </form>
      </Modal>
    </>
  );
}
