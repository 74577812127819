import {
  AnalyticsClient,
  AnalyticsServer,
  CTClient,
} from '@cointracker/analytics';
import { isClient } from '@cointracker/ui';

let analytics: AnalyticsClient | AnalyticsServer;
if (isClient()) {
  analytics = new AnalyticsClient();
  analytics.init(
    import.meta.env.PUBLIC_ANALYTICS_WRITE_KEY,
    import.meta.env.PUBLIC_MIXPANEL_TOKEN,
    {
      isAuthenticated: false,
    },
    CTClient.webApp,
    import.meta.env.PUBLIC_SEGMENT_CDN_HOST,
  );
} else {
  analytics = new AnalyticsServer();
}

/**
 * Use this function for a tracking sign up event that appears on a specific page.
 *
 * When to use this:
 * A sign up button appearing on a specific logged out marketing page. A home page Sign Up CTA.
 *
 * In the rare case you have a button that is appearing on every logged out page, like on the navbar, use `trackLoggedOutGlobalSignUpButtonClicked` instead to default to our global logged out page title.
 *
 * @param button_text what does the button text display to the user?
 * @param section_name what is the name of the section that the button is in?
 * @param section_title what is the title of the section that the button is in?
 * @param page_title what is the title of the page that the button is in?
 */
export function trackSignUpButtonClicked(
  button_text: string,
  section_name: string,
  section_title: string,
  page_title: string | null,
) {
  analytics.track('Sign Up Button Clicked', {
    button_text: button_text,
    page_title: page_title,
    path: window.location.pathname,
    section_title: section_title,
    section: section_name,
  });
}

const GLOBAL_LOGGED_OUT_PAGE_TITLE = 'All Logged Out Pages';

/**
 * Use this function for a tracking sign up event that appears on all logged out pages.
 * It automatically applies the global logged out page title.
 *
 * When to use this: A sign up button on the top navigation bar of our site if you aren't logged in.
 *
 * Warning:
 *
 * Most of the time you should use `trackSignUpButtonClicked` instead of this function.
 *
 * @param button_text what does the button text display to the user?
 * @param section_name what is the name of the section that the button is in?
 * @param section_title what is the title of the section that the button is in?
 */
export function trackLoggedOutGlobalSignUpButtonClicked(
  button_text: string,
  section_name: string,
  section_title: string,
) {
  analytics.track('Sign Up Button Clicked', {
    button_text: button_text,
    page_title: GLOBAL_LOGGED_OUT_PAGE_TITLE,
    path: window.location.pathname,
    section_title: section_title,
    section: section_name,
  });
}

export default analytics;
