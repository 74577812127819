import styled from 'styled-components';

export const SROnly = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

export const Container = styled.div<{ $loading: boolean }>`
  display: ${({ $loading }) => ($loading ? 'block' : 'none')};
  text-align: center;
  padding: 12px;
`;

export const Spinner = styled.div`
  @keyframes spinner-border {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;

  @media (prefers-reduced-motion: reduce) {
    .spinner-border {
      -webkit-animation-duration: 1.5s;
      animation-duration: 1.5s;
    }
  }
`;
