import { CustomWallet, Exchange, Wallet } from '@cointracker/graphql-types';
import { Dayjs } from 'dayjs';
import englishLocale from 'dayjs/locale/en';
import dayjs from 'src/app/utils/dayjsConfig';
import firstWalletIllustrationUrl from 'src/assets/first-wallet-illustration.webp?url';
import somethingWentWrongIllustration from 'src/assets/something-went-wrong-illustration.webp?url';
import upToDateIllustrationUrl from 'src/assets/up-to-date-illustration.webp?url';

import { StatusReadyState } from './hooks/useGetStatus';

export const getStatusText = (status: StatusReadyState) => {
  switch (status) {
    case StatusReadyState.Syncing:
      return 'Syncing...';
    case StatusReadyState.Calculating:
      return 'Calculating...';
    case StatusReadyState.Failed:
      return 'Calculation error';
    case StatusReadyState.Loading:
      return 'Loading';
    case StatusReadyState.EmptyData:
      return 'Add wallet';
    case StatusReadyState.Ready:
    default:
      return 'Up to date';
  }
};

export const getExpandedStatusText = (status: StatusReadyState) => {
  switch (status) {
    case StatusReadyState.Syncing:
      return 'Syncing...';
    case StatusReadyState.Calculating:
      return 'Calculating...';
    case StatusReadyState.Failed:
      return 'Something went wrong';
    case StatusReadyState.Loading:
      return 'Loading';
    case StatusReadyState.EmptyData:
      return 'Add your first wallet';
    case StatusReadyState.Ready:
    default:
      return 'Up to date';
  }
};

export const getExpandedStatusImage = (status: StatusReadyState) => {
  switch (status) {
    case StatusReadyState.Syncing:
      return null;
    case StatusReadyState.Calculating:
      return null;
    case StatusReadyState.Failed:
      return {
        src: somethingWentWrongIllustration,
        alt: 'Image depicting something went wrong',
      };
    case StatusReadyState.Loading:
      return null;
    case StatusReadyState.EmptyData:
      return {
        src: firstWalletIllustrationUrl,
        alt: 'Image depicting empty wallet data',
      };
    case StatusReadyState.Ready:
    default:
      return {
        src: upToDateIllustrationUrl,
        alt: 'Image depicting up to date wallet data',
      };
  }
};

export const getMostRecentlySyncedDate = (
  walletsOrAccounts: (Exchange | Wallet | CustomWallet)[],
) => {
  const mostRecentlySyncedDate = walletsOrAccounts.reduce(
    (acc: Dayjs, walletOrAccount) => {
      if (!walletOrAccount.syncInfo.lastSynced) return acc;
      const currentSyncDayJs = dayjs(walletOrAccount.syncInfo.lastSynced);
      if (!acc) return currentSyncDayJs;
      return currentSyncDayJs.isAfter(acc) ? currentSyncDayJs : acc;
    },
    null,
  );
  return dayjs(mostRecentlySyncedDate).locale(englishLocale).fromNow();
};
