import {
  usePollTaxReportQuery,
  useStartUniversalToPerWalletMigrationMutation,
} from '@cointracker/graphql-types';
import {
  Banner,
  Modal,
  Status,
  ToastContext,
  Tooltip,
} from '@cointracker/styleguide';
import { Body3, Button } from '@cointracker/styleguide/src/LoggedIn';
import React, { useCallback, useContext, useEffect, useState } from 'react';

const MAX_NUMBER_OF_POLLS = 1500;

export interface BannerProps {
  className?: string;
  disabled: boolean;
  disabledTooltipContent?: React.ReactNode;
  onMigrationStarted?: () => void;
  onMigrationFinished?: () => void;
}

export const UniversalToPerWalletMigrationBanner = ({
  className,
  disabled,
  disabledTooltipContent,
  onMigrationStarted,
  onMigrationFinished,
}: BannerProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { showNotification } = useContext(ToastContext);
  const onCloseModal = () => setIsModalOpen(false);
  const [pollingJobId, setPollingJobId] = useState(null);
  const [pollNumber, setPollNumber] = useState(0);

  const [mutate, { loading: loadingMutation, error: errorMutation }] =
    useStartUniversalToPerWalletMigrationMutation({
      refetchQueries: ['GetUser', 'GetSettings'],
      onCompleted: (data) => {
        const jobId = data?.startUniversalToPerWalletMigration?.jobId;
        if (data?.startUniversalToPerWalletMigration?.success || !jobId) {
          showNotification({
            message:
              'The universal to per-wallet migration has been started and may take several minutes to complete. Allocation reports are being prepared for download and will be emailed to you for your records.',
            status: Status.Success,
            delay: 30000,
          });
          setPollingJobId(jobId);
          setIsModalOpen(false);
          onMigrationStarted?.();
        } else {
          showNotification({
            message:
              'There was an error while starting the universal to per-wallet migration',
            status: Status.Error,
          });
        }
      },
      // needed in order for the error TEST case to not fail :facepalm:
      // More info on the apollo github issue https://github.com/apollographql/apollo-client/issues/7167
      onError: () => {},
    });

  const { startPolling, stopPolling } = usePollTaxReportQuery({
    variables: {
      jobId: pollingJobId,
    },
    fetchPolicy: 'network-only',
    skip: !pollingJobId,
    onCompleted(data) {
      setPollNumber(pollNumber + 1);
      if (pollNumber > MAX_NUMBER_OF_POLLS) {
        setPollingJobId(null);
        showNotification({
          message:
            'Failed to migrate from universal to per-wallet, please try again later.',
          status: Status.Error,
        });
      } else {
        const { success, url } = data?.taxReportPollResult || {};
        if (success && url) {
          window.location.href = url;
          setPollingJobId(null);
          onMigrationFinished?.();
        }
      }
    },
    onError() {
      setPollNumber(pollNumber + 1);
    },
  });

  useEffect(() => {
    setPollNumber(0);
    if (pollingJobId) {
      startPolling(2000);
    } else {
      stopPolling();
    }
    return () => {
      stopPolling();
    };
  }, [pollingJobId, startPolling, stopPolling]);

  const isLoading = loadingMutation;
  const onSaveChanges = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (isLoading) {
        return;
      }
      mutate();
    },
    [isLoading, mutate],
  );
  return (
    <>
      <div className={className}>
        <div className="flex items-center gap-8 rounded-16 border border-line-primary p-16">
          <div className="gap-4p-8 flex flex-col">
            <div>
              As of January 1, 2025, Universal cost basis tracking is no longer
              accepted by the IRS. Users who previously filed using Universal
              must switch to Per-Wallet tracking and allocate their Universal
              lots to specific wallets. To do so:
            </div>
            <ol className="flex list-inside list-decimal flex-col gap-4">
              <li>
                Set your Tax lot allocation method in your{' '}
                <a href="/user/profile/tax" className="text-text-link">
                  tax settings
                </a>{' '}
                page
              </li>
              <li>
                Reconcile your 2024 transactions by adding all missing wallets,
                resolving all{' '}
                <a
                  href="/transactions?page=1&q=has%3Areview"
                  className="text-text-link"
                >
                  &quot;needs review&quot; transactions
                </a>
                , and ensuring your exchange balances in CoinTracker match your
                exchange accounts
              </li>
              <li>
                Once you have completed the above, select &apos;Migrate
                Now&apos; to continue
              </li>
            </ol>
          </div>
          <div className="flex-nowrap">
            {disabled && disabledTooltipContent ? (
              <Tooltip content={disabledTooltipContent}>
                <Button
                  variant="primary"
                  size="medium"
                  disabled={true}
                  className="whitespace-nowrap"
                >
                  Migrate Now
                </Button>
              </Tooltip>
            ) : (
              <Button
                variant="primary"
                size="medium"
                onClick={() => setIsModalOpen(true)}
                className="whitespace-nowrap"
              >
                Migrate Now
              </Button>
            )}
          </div>
        </div>
      </div>
      <Modal.Root open={isModalOpen} onOpenChange={() => onCloseModal()}>
        <Modal.Content>
          <Modal.ContentHeader title="Migrate from Universal to Per-Wallet tracking" />
          {errorMutation && (
            <div className="mb-8">
              <Banner type="error" closable={false}>
                There was an error while starting the universal to per-wallet
                migration
              </Banner>
            </div>
          )}
          <Modal.ContentBody>
            <Modal.Description>
              <Body3 className="block">
                By migrating now, your historical transactions up to December
                31, 2024 will be frozen so that no changes can be made. Please
                ensure you&apos;ve reconciled all transactions up to December
                31, 2024, added all missing wallets, resolved all &quot;needs
                review&quot; transactions, and that your exchange account
                balances in CoinTracker match your exchanges.
                <p className="font-bold text-text-primary">
                  Please select confirm if you are ready to migrate now.
                </p>
                <p className="font-bold italic text-text-primary">
                  This action is irreversible.
                </p>
              </Body3>
            </Modal.Description>
          </Modal.ContentBody>
          <Modal.ContentFooter className="flex flex-col gap-16">
            <Button
              size="small"
              onClick={onSaveChanges}
              disabled={isLoading}
              fluid
            >
              Confirm
            </Button>
            <Button
              variant="line"
              size="small"
              fluid
              onClick={() => onCloseModal()}
              disabled={isLoading}
            >
              Cancel
            </Button>
          </Modal.ContentFooter>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};

export default UniversalToPerWalletMigrationBanner;
