import React from 'react';
import styled from 'styled-components';
import { lightTheme } from '../../components/colors/theme';

export enum SpinnerVariants {
  Small,
  Medium,
  Large,
}

interface SpinnerProps extends React.HTMLAttributes<HTMLSpanElement> {
  variant?: SpinnerVariants;
  color?: string;
}

const SIZES = {
  [SpinnerVariants.Small]: {
    size: 16,
    thickness: 2,
  },
  [SpinnerVariants.Medium]: {
    size: 24,
    thickness: 3,
  },
  [SpinnerVariants.Large]: {
    size: 32,
    thickness: 4,
  },
};

export const Spinner = ({
  variant = SpinnerVariants.Medium,
  color = lightTheme.grey.white,
  ...props
}: SpinnerProps) => {
  return (
    <StyledSpinner {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={SIZES[variant].size}
        height={SIZES[variant].size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <g fill={color}>
          <path
            d="M8 16a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8ZM8 2a6 6 0 1 0 0 12A6 6 0 0 0 8 2Z"
            opacity=".4"
          />
          <path d="M16 8h-2a6.006 6.006 0 0 0-6-6V0a8.009 8.009 0 0 1 8 8Z" />
        </g>
      </svg>
    </StyledSpinner>
  );
};

const StyledSpinner = styled.span`
  display: flex;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Spinner;
