import * as Dialog from '@radix-ui/react-dialog';
import React, { ReactNode } from 'react';
import {
  DialogBody,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
} from './styles';

// documentation https://www.radix-ui.com/docs/primitives/components/dialog
export function Modal({
  children,
  className,
  hide,
  ...props
}: Dialog.DialogProps & { className?: string; hide?: boolean }) {
  const onPointerDownOutside: Dialog.DialogContentProps['onPointerDownOutside'] =
    (event) => {
      event.preventDefault();
    };
  return (
    <Dialog.Root {...props}>
      <Dialog.Portal>
        {!hide && <DialogOverlay />}
        <DialogContent
          className={className}
          onPointerDownOutside={hide ? onPointerDownOutside : undefined}
        >
          {children}
        </DialogContent>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

interface ModalHeaderProps {
  title: string | ReactNode;
}
export function ModalHeader({ title }: ModalHeaderProps) {
  return (
    <DialogHeader>
      <DialogTitle>{title}</DialogTitle>
      <Dialog.Close asChild aria-label="Close">
        <DialogClose>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 2.5L2.5 13.5"
              stroke="#A2B1C5"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M2.5 2.5L13.5 13.5"
              stroke="#A2B1C5"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
          </svg>
        </DialogClose>
      </Dialog.Close>
    </DialogHeader>
  );
}

export const ModalBody = DialogBody;
export const ModalDescription = DialogDescription;
export const ModalFooter = DialogFooter;
