import {
  darkTheme as rebrandDarkTheme,
  lightTheme as rebrandLightTheme,
} from '@cointracker/styleguide';
import {
  darkThemeColors as rebrandDarkThemeColors,
  lightThemeColors as rebrandLightThemeColors,
} from './rebrandThemeColors';
type ColorVariables = {
  [key: string]: {
    [key: string]: string;
  };
};

export interface ThemeProps {
  name: string;
  className?: string;
  rebrand?: typeof rebrandLightTheme;
  primary: {
    solid: string;
    hover: string;
    clicked: string;
  };
  secondary: {
    yellow: string;
    orange: string;
    green: string;
    red: string;
  };
  red: {
    solid: string;
    hover: string;
    clicked: string;
  };
  tint: {
    blue: string;
    green: string;
    red: string;
    yellow: string;
  };
  grey: {
    white: string;
    light: string;
    mid: string;
    strong: string;
    dark: string;
  };
  gradient: {
    blue: string;
    dark: string;
    loading: string;
    loadingBackground: string;
    upsell: string;
    highlight: string;
  };
  text: {
    primary: string;
    secondary: string;
    tertiary: string;
    blue: string;
  };
  textInverted: {
    primary: string;
    secondary: string;
    tertiary: string;
    blue: string;
  };
  social: {
    coinbase: string;
    google: string;
    cch: string;
    turbotax: string;
  };
  shadow: {
    level1: string;
    level2: string;
    level3: string;
    level4: string;
  };
  variables?: {
    colors?: ColorVariables;
    gradients?: ColorVariables;
    generic?: ColorVariables;
  };
}

export interface PartnerThemeProps {
  primary: {
    solid: string;
    hover: string;
    clicked: string;
  };
  grey: {
    white: string;
    light: string;
    mid: string;
    strong: string;
    dark: string;
  };
}

const genericVariables = {
  'border-radius': {
    small: '4px',
    medium: '8px',
    big: '100px',
  },
};

export const lightThemeColors = {
  neutral: {
    '0': '#FFFFFF',
    '100': '#FAFCFF',
    '200': '#F4F6FA',
    '300': '#E5E8ED',
    '400': '#A3ACB9',
    '500': '#848B96',
    '700': '#65748B',
    '800': '#606B7A',
    '900': '#23272E',
  },
  primary: {
    '100': '#EBF9FF',
    '200': '#CCF2FF',
    '300': '#99E7FF',
    '400': '#63D7FF',
    '650': '#0182FF',
    '700': '#0182FF',
    '800': '#0171DE',
    '900': '#0160BD',
  },
  purple: {
    '100': '#F5E5FF',
    '700': '#8900EB',
    '800': '#7706C3',
    '900': '#62079C',
  },
  green: {
    '100': '#E5FFF4',
    '700': '#009B6C',
    '800': '#007C5A',
    '900': '#036248',
  },
  amber: {
    '100': '#FFFDE0',
    '500': '#FCBC04',
    '600': '#DB7712',
    '700': '#B96704',
    '800': '#964F0A',
    '900': '#80430C',
  },
  red: {
    '100': '#FFE5E7',
    '700': '#E51538',
    '800': '#C50B2F',
    '900': '#A50C2F',
  },
  'text-icon': {
    primary: '#0F182A',
    secondary: '#5B616E',
    disabled: '#6F747F',
    'primary-white': '#FFFFFF',
    'secondary-white': '#B7B8BA',
    'disabled-white': '#9C9DA1',
  },
};

export const lightThemeGradients = {
  gradient: {
    neutral:
      'linear-gradient(180deg, rgba(68, 76, 96, 0.15) 0%, rgba(107, 114, 128, 0.01) 107.54%)',
    neutralVertical:
      'linear-gradient(240deg, rgba(68, 76, 96, 0.15) 0%, rgba(107, 114, 128, 0.01) 107.54%)',
    primary:
      'linear-gradient(180deg, rgba(1, 130, 255, 0.15) 0%, rgba(1, 87, 255, 0.01) 100%)',
    secondary:
      'linear-gradient(180deg, rgba(137, 0, 235, 0.15) 0%, rgba(137, 0, 235, 0.01) 100%)',
    green:
      'linear-gradient(180deg, rgba(0, 155, 108, 0.15) 0%, rgba(8, 135, 93, 0.01) 100%)',
    yellow:
      'linear-gradient(180deg, rgba(252, 188, 4, 0.15) 0%, rgba(255, 195, 16, 0.01) 100%)',
    red: 'linear-gradient(180deg, rgba(229, 21, 56, 0.15) 0%, rgba(220, 40, 46, 0.01) 100%)',
    upsell:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.00) -32.8%, #FFF 34.32%)',
    highlight:
      'linear-gradient(249deg, #0066fe 0%, rgba(205, 4, 255, 0.34) 131.14%)',
  },
};

export const darkThemeColors = {
  neutral: {
    '0': '#10111C',
    '100': '#1E1F2A',
    '200': '#2A2B38',
    '300': '#3B3D51',
    '400': '#444660',
    '500': '#535676',
    '700': '#7E819E',
    '800': '#B8BAC9',
    '900': '#FFFFFF',
  },
  primary: {
    '100': '#0F3963',
    '200': '#14426F',
    '300': '#0166C8',
    '400': '#0182FF',
    '650': '#3DB1F5',
    '700': '#0094FF',
    '800': '#1EA1FF',
    '900': '#45ACF5',
  },
  purple: {
    '100': '#430076',
    '700': '#B335FF',
    '800': '#C76CFF',
    '900': '#D38BFF',
  },
  green: {
    '100': '#044233',
    '700': '#52DFA2',
    '800': '#7CE3B7',
    '900': '#9CEDCA',
  },
  amber: {
    '100': '#5E2C12',
    '500': '#EB6321',
    '600': '#F07437',
    '700': '#EDCA1C',
    '800': '#EFD551',
    '900': '#F2DF7B',
  },
  red: {
    '100': '#5C0418',
    '700': '#FB8392',
    '800': '#FC9AA6',
    '900': '#FFB0B9',
  },
  'text-icon': {
    primary: '#FFFFFF',
    secondary: '#B7B8BA',
    disabled: '#9C9DA1',
    'primary-white': '#0F182A',
    'secondary-white': '#5B616E',
    'disabled-white': '#6F747F',
  },
};

export const darkThemeGradients = {
  gradient: {
    neutral:
      'linear-gradient(180deg, rgba(135, 146, 169, 0.15) 0%, rgba(107, 114, 128, 0.01) 100%)',
    neutralVertical:
      'linear-gradient(240deg, rgba(135, 146, 169, 0.15) 0%, rgba(107, 114, 128, 0.01) 100%)',
    primary:
      'linear-gradient(180deg, rgba(72, 208, 255, 0.15) 0%, rgba(72, 208, 255, 0.02) 100%)',
    secondary:
      'linear-gradient(180deg, rgba(199, 108, 255, 0.15) 0%, rgba(199, 108, 255, 0.01) 100%)',
    green:
      'linear-gradient(180deg, rgba(126, 231, 186, 0.15) 0%, rgba(106, 235, 185, 0.01) 100%)',
    yellow:
      'linear-gradient(180deg, rgba(239, 211, 72, 0.15) 0%, rgba(255, 235, 72, 0.01) 100%)',
    red: 'linear-gradient(180deg, rgba(252, 133, 147, 0.15) 0%, rgba(255, 161, 167, 0.01) 100%)',
    upsell: 'linear-gradient(180deg, #121317 -32.8%, #121317 34.32%)',
    highlight:
      'linear-gradient(249deg, #0066fe 0%, rgba(205, 4, 255, 0.34) 131.14%)',
  },
};

export const getTheme = (name = 'light', colors, gradients) => ({
  name,
  rebrand: name === 'light' ? rebrandLightTheme : rebrandDarkTheme,
  primary: {
    solid: colors.primary['700'],
    hover: colors.primary['800'],
    clicked: colors.primary['900'],
  },
  secondary: {
    yellow: colors.amber['500'],
    orange: colors.amber['600'],
    green: colors.green['700'],
    red: colors.red['700'],
  },
  red: {
    solid: colors.red['700'],
    hover: colors.red['800'],
    clicked: colors.red['900'],
  },
  tint: {
    blue: colors.primary['100'],
    green: colors.green['100'],
    red: colors.red['100'],
    yellow: colors.amber['100'],
  },
  grey: {
    white: colors.neutral['0'],
    light: colors.neutral['100'],
    mid: colors.neutral['300'],
    strong: colors.neutral['500'],
    dark: colors.neutral['900'],
  },
  gradient: {
    blue: gradients.gradient.primary,
    dark: gradients.gradient.neutral,
    loading: gradients.gradient.neutralVertical,
    loadingBackground: colors.neutral['100'],
    upsell: gradients.gradient.upsell,
    highlight: darkThemeGradients.gradient.highlight,
  },
  text: {
    primary: colors['text-icon'].primary,
    secondary: colors['text-icon'].secondary,
    tertiary: colors['text-icon'].disabled,
    blue: colors.primary['650'],
  },
  textInverted: {
    primary: colors['text-icon']['primary-white'],
    secondary: colors['text-icon']['secondary-white'],
    tertiary: colors['text-icon']['disabled-white'],
    blue: colors.primary['650'],
  },
  social: {
    coinbase: '#1652F0',
    google: '#DA503F',
    cch: '#00599C',
    turbotax: '#008080',
  },
  shadow: {
    level1: '0px 8px 56px rgba(194, 203, 216, 0.2)',
    level2: '0px 24px 56px rgba(194, 203, 216, 0.4)',
    level3: '0px 8px 16px rgba(133, 138, 143, 0.2)',
    level4: '0px 8px 32px rgba(194, 203, 216, 0.16)',
  },
  variables: {
    colors: colors,
    gradients: gradients,
    generic: genericVariables,
  },
});
export const lightTheme: ThemeProps = {
  ...getTheme('light', lightThemeColors, lightThemeGradients),
  social: {
    coinbase: '#1652F0',
    google: '#DA503F',
    cch: '#00599C',
    turbotax: '#008080',
  },
  shadow: {
    level1: '0px 8px 56px rgba(194, 203, 216, 0.2)',
    level2: '0px 24px 56px rgba(194, 203, 216, 0.4)',
    level3: '0px 8px 16px rgba(133, 138, 143, 0.2)',
    level4: '0px 8px 32px rgba(194, 203, 216, 0.16)',
  },
};

export const lightThemeRebrand: ThemeProps = {
  ...getTheme('light', rebrandLightThemeColors, lightThemeGradients),
  social: {
    coinbase: '#1652F0',
    google: '#DA503F',
    cch: '#00599C',
    turbotax: '#008080',
  },
  shadow: {
    level1: '0px 8px 56px rgba(194, 203, 216, 0.2)',
    level2: '0px 24px 56px rgba(194, 203, 216, 0.4)',
    level3: '0px 8px 16px rgba(133, 138, 143, 0.2)',
    level4: '0px 8px 32px rgba(194, 203, 216, 0.16)',
  },
};

export const darkTheme: ThemeProps = {
  ...getTheme('dark', darkThemeColors, darkThemeGradients),
  social: {
    coinbase: '#1652F0',
    google: '#DA503F',
    cch: '#00599C',
    turbotax: '#008080',
  },
  shadow: {
    level1: '0px 8px 56px rgba(4, 13, 27, 0.12)',
    level2: '0px 24px 56px rgba(4, 13, 27, 0.4)',
    level3: '0px 8px 16px rgba(4, 13, 27, 0.2)',
    level4: '0px 8px 32px rgba(4, 13, 27, 0.16)',
  },
};

export const darkThemeRebrand: ThemeProps = {
  ...getTheme('dark', rebrandDarkThemeColors, darkThemeGradients),
  social: {
    coinbase: '#1652F0',
    google: '#DA503F',
    cch: '#00599C',
    turbotax: '#008080',
  },
  shadow: {
    level1: '0px 8px 56px rgba(4, 13, 27, 0.12)',
    level2: '0px 24px 56px rgba(4, 13, 27, 0.4)',
    level3: '0px 8px 16px rgba(4, 13, 27, 0.2)',
    level4: '0px 8px 32px rgba(4, 13, 27, 0.16)',
  },
};

export const TurboTaxTheme: PartnerThemeProps = {
  primary: {
    solid: '#008080',
    hover: '#009494',
    clicked: '#006e6e',
  },
  grey: {
    white: '#FFFFFF',
    light: '#FAFCFF',
    mid: '#E7EBF0',
    strong: '#A2B1C5',
    dark: '#03122E',
  },
};

export const HRBlockTheme: PartnerThemeProps = {
  primary: {
    solid: '#02AD4E',
    hover: '#04c65b',
    clicked: '#028d41',
  },
  grey: {
    white: '#FFFFFF',
    light: '#FAFCFF',
    mid: '#E7EBF0',
    strong: '#A2B1C5',
    dark: '#03122E',
  },
};

export const EtoroTheme: PartnerThemeProps = {
  primary: {
    solid: '#13C636',
    hover: '#16E53E',
    clicked: '#13C636',
  },
  grey: {
    white: '#FFFFFF',
    light: '#FAFCFF',
    mid: '#E7EBF0',
    strong: '#A2B1C5',
    dark: '#03122E',
  },
};

export const UpholdTheme: PartnerThemeProps = {
  primary: {
    solid: '#02AD4E',
    hover: '#04c65b',
    clicked: '#028d41',
  },
  grey: {
    white: '#FFFFFF',
    light: '#FAFCFF',
    mid: '#E7EBF0',
    strong: '#A2B1C5',
    dark: '#03122E',
  },
};

export const EmberTheme: PartnerThemeProps = {
  primary: {
    solid: '#00DE3E',
    hover: '#04c65b',
    clicked: '#028d41',
  },
  grey: {
    white: '#FFFFFF',
    light: '#FAFCFF',
    mid: '#E7EBF0',
    strong: '#A2B1C5',
    dark: '#03122E',
  },
};

export const BlockchainTheme: PartnerThemeProps = {
  primary: {
    solid: '#0182FF',
    hover: '#2594FF',
    clicked: '#0C66F5',
  },
  grey: {
    white: '#FFFFFF',
    light: '#FAFCFF',
    mid: '#E7EBF0',
    strong: '#A2B1C5',
    dark: '#03122E',
  },
};

export const PhantomTheme: PartnerThemeProps = {
  primary: {
    solid: '#453DB0',
    hover: '#6259de',
    clicked: '#2e2975',
  },
  grey: {
    white: '#FFFFFF',
    light: '#FAFCFF',
    mid: '#E7EBF0',
    strong: '#A2B1C5',
    dark: '#03122E',
  },
};

export const CoinbaseTheme: PartnerThemeProps = {
  primary: {
    solid: '#1652F0',
    hover: '#204EF2',
    clicked: '#0C66F5',
  },
  grey: {
    white: '#FFFFFF',
    light: '#FAFCFF',
    mid: '#E7EBF0',
    strong: '#A2B1C5',
    dark: '#03122E',
  },
};

export const BitcoinTheme: PartnerThemeProps = {
  primary: {
    solid: '#FC8209',
    hover: '#F39036',
    clicked: '#F5A256',
  },
  grey: {
    white: '#FFFFFF',
    light: '#FAFCFF',
    mid: '#E7EBF0',
    strong: '#A2B1C5',
    dark: '#03122E',
  },
};

export const KrakenTheme: PartnerThemeProps = {
  primary: {
    solid: '#5B3ED6',
    hover: '#7867DB',
    clicked: '#482AC8',
  },
  grey: {
    white: '#FFFFFF',
    light: '#FAFCFF',
    mid: '#E7EBF0',
    strong: '#A2B1C5',
    dark: '#03122E',
  },
};

export const MetamaskTheme: PartnerThemeProps = {
  primary: {
    solid: '#0E67CD',
    hover: '#1077ED',
    clicked: '#0C57AD',
  },
  grey: {
    white: '#FFFFFF',
    light: '#FAFCFF',
    mid: '#E7EBF0',
    strong: '#A2B1C5',
    dark: '#03122E',
  },
};

export const TaxCaddyTheme: PartnerThemeProps = {
  primary: {
    solid: '#2367A2',
    hover: '#16E53E',
    clicked: '#13C636',
  },
  grey: {
    white: '#FFFFFF',
    light: '#FAFCFF',
    mid: '#E7EBF0',
    strong: '#A2B1C5',
    dark: '#03122E',
  },
};

export const isDarkTheme = (theme: ThemeProps) => theme.name === darkTheme.name;
