import { useContext } from 'react';
import { AnalyticsContext } from '../context';

/**
 * @returns analytics library - currently only supported methods are: identify, track, page
 */
export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);

  if (!context) {
    throw new Error('Analytics context used outside of its Provider!');
  }

  return context?.analytics;
};
