import { type Location } from 'react-router-dom';

export const URLS = {
  CONTACT_US: 'contact-us',
  CREATE_ACCOUNT_BETA: 'create-beta',
  ONBOARDING: {
    SETTINGS: '/onboarding',
    ADD_WALLET: '/onboarding?add_integration=',
    ADD_WALLET_METAMASK: '/onboarding?add_integration=metamask_wallet',
    FILE_TAXES: '/onboarding/file-taxes',
    RESOLVE_TRANSACTIONS: '/onboarding/resolve-transactions',
    SUCCESS: '/onboarding/success',
    WELCOME: '/onboarding/welcome',
    JOINED_WELCOME: '/onboarding/joined-welcome',
    QUIZ: '/onboarding/quiz',
    CONFIRM_SETTINGS: '/onboarding/confirm-settings',
    ADD_WALLETS: '/onboarding/add-wallet',
    ADD_ANOTHER_WALLET: '/onboarding/add-another-wallet',
    FEATURE_INTRODUCTION_WELCOME: '/onboarding/feature-introduction-welcome',
    FEATURE_INTRODUCTION_TAXES: '/onboarding/feature-introduction-taxes',
    FEATURE_INTRODUCTION_PERFORMANCE:
      '/onboarding/feature-introduction-performance',
  },
  LOGIN: 'login',
  PRIVACY: 'privacy',
  SIGNED_IN: 'user/signed-in',
  SECURITY: 'security',
  BLOG_TAX_GUIDE: 'blog/crypto-tax-guide',
  BLOG_TAX_GUIDE_CPA: 'blog/crypto-tax-guide-for-cpas',
  BRAND: 'https://brand.cointracker.com/',
  TAX_PLANS: 'tax/plans',
  TERMS: 'terms',
  TRANSACTIONS: 'transactions',
  PORTFOLIO_SUBSCRIPTION: 'portfolio/subscription',
  PORTFOLIO_PAGE: '/portfolio',
  GET_CURRENT_USER: '/api/get-current-user',
  WALLETS: '/wallets',
  WALLET_IMPORT: '/import',
  USER_REGISTER: 'user/register',
  REPORTS: '/reports',
  CALCULATOR: '/calculator',
  FEATURES: '/features',
  PORTFOLIO_TRACKER: '/portfolio-tracker',
  GET_STARTED_PAGE: '/user/start',
  BLOG: '/blog',
  SUPPORT: '/support',
  TAX_PROS_LANDING_PAGE: '/tax-pros',
  AUTH_LOGIN: '/auth0/login',
  AUTH_LOGOUT: '/auth0/logout',
  AUTH_SIGNUP: '/auth0/signup',
  ENABLE_MFA: '/auth0/enable_mfa',
  DISABLE_MFA: '/auth0/disable_mfa',
  MAIN_PAGE: '/',
  HOME_PAGE: '/home',
  DASHBOARD_PAGE: '/dashboard',
  WALLETS_PAGE: '/wallets',
  TRANSACTIONS_PAGE: '/transactions',
  TRANSACTIONS_PAGE_NEEDS_REVIEW: '/transactions?page=1&q=has:review',
  ADD_WALLET: '/add_wallet',
  PERFORMANCE_PAGE: '/performance',
  RECONCILIATION: '/account-health',
  PRICES_PAGE: '/prices',
  PRICES_ALL_PAGE: '/prices/all',
  PRICES_WATCHLIST_PAGE: '/prices/watchlist',
  TAX_LOSS_HARVEST: '/tax-loss-harvest',
  PORTFOLIO_PLANS_PAGE: '/plans',
  UNIFIED_SUBSCRIPTION_PLANS_PAGE: '/plans',
  UNIFIED_SUBSCRIPTIONS_PLANS_CHECKOUT_PAGE: '/unified-checkout',
  UNIFIED_SUBSCRIPTIONS_PLANS_MANAGE_PAGE: '/subscription/manage',
  UNIFIED_SUBSCRIPTIONS_RENEW_PLAN: '/subscription/renew',
  UNIFIED_SUBSCRIPTIONS_UPDATE_PLAN_PAGE: '/subscription/update',
  PORTFOLIO_PLANS_CHECKOUT_PAGE: '/subscription-checkout',
  PORTFOLIO_PLANS_MANAGE_PAGE: '/portfolio/subscription/manage',
  ADD_TRANSACTION: '/add_transaction',
  ADD_ACCOUNT: '/add_wallet',
  UPDATE_ACCOUNT: '/accounts',
  IMPORT_ACCOUNTS_BY_TYPE: '/accounts',
  CUSTOM_WALLETS: '/custom_wallets',
  COIN_PAGE: '/price',
  TAX_PAGE: '/tax',
  TAX_LOTS: '/tax-lots',
  // TODO: the proper url here is /tax/** but i am not sure where to get the number here from. Should we just check the
  //       current year from the window?
  TAX_PAGE_FOR_YEAR: '/tax/2022',
  EDIT_USER_PROFILE: '/user/profile',
  EDIT_USER_PROFILE_TAX: '/user/profile/tax',
  INTEGRATIONS_PAGE: '/integrations',
  TAX_PLANS_PAGE: '/tax/plans',
  WK_LANDING: '/partner/wk',
  VIEW_TAX_PRO_DASHBOARD: '/tax-pros/dashboard',
  VIEW_TAX_PROS_INCOMING_INVITES: '/tax-pros/view-incoming-invites',
  VIEW_TAX_PRO_CLIENTS: '/tax-pros/client-view',
  VIEW_TAX_PRO: '/tax-pros/tax-pro-view',
  TAX_PRO_FIRM_VIEW: '/tax-pros/firm-view',
  LOT_IMPORTER: '/lot-importer-beta',
  LOT_IMPORTER_WAITING: '/lot-importer-waiting',
  COINBASE_ONE_PROMOTION: '/promotion/coinbase-one',
  COINBASE_ONE_LANDING: '/coinbaseone',
  EXTERNAL_COINBASE_ONE_BOUNCE_LINK:
    'https://www.coinbase.com/one?action=verify_cointracker',
  EXTERNAL_COINBASE_ONE_PROMOTION_LINK:
    'https://www.coinbase.com/one?member=true&promo=cointracker&referrer=cointracker',
  CONNECT_INTAKE_FORM: '/connect/intake',
  CONNECT_LANDING_PAGE: '/connect',
  CONNECT_COINBASE: '/connect?referral-code=coinbase_discount',
  CONNECT_UNISWAP: '/connect?referral-code=uniswap_discount',
  CONNECT_PHANTOM: '/connect?referral-code=phantom_discount',
  CONNECT_OPENSEA: '/connect?referral-code=opensea_discount',
  CONNECT_TRUST: '/connect?referral-code=trust_discount',
  CONNECT_BRAVE: '/connect?referral-code=brave_discount',
  CONNECT_TAXCADDY: '/connect?referral-code=taxcaddy_discount',
  CONNECT_TAX_PAGE:
    '/connect?utm_source=tax-center-find-tax-professional-button',
  NEW_SUPPORT_TICKET: 'https://support.cointracker.io/hc/en-us/requests/new',
  VIEW_AS: '/user/view-as',
  DISCLAIMER: '/disclaimer',
  STATUS: 'https://status.cointracker.io',
  ABOUT: '/about',
  CAREERS: '/careers',
  PRESS: 'https://www.cointracker.io/p/press',
  DEALS: 'https://www.cointracker.io/p/deals',
  CPA_EXTERNAL: 'https://www.cointracker.io/tax-pros',
  APPLE_APP_STORE:
    'https://apps.apple.com/us/app/cointracker-crypto-portfolio/id1401499763',
  GOOGLE_PLAY_STORE:
    'https://play.google.com/store/apps/details?id=io.cointracker.android',
  S3_STATIC_IMAGES: 'https://cdn.cointracker.io/static/images',
  S3_STATIC_FILES: 'https://cdn.cointracker.io/static/files',
  NOT_FOUND_PAGE: '/not_found',
  TIPS: '/tips',
  REVIEW: '/tips/balance-differences',
  CANNY_NEW_POST: 'https://feedback.cointracker.io/new-posts',
  TAX_PRO_REQUEST_ACCESS_FORM:
    'https://docs.google.com/forms/d/e/1FAIpQLSc5iAT3WnpltytA1xkX1pdv0brLd0jZ74Yej5gBsmnDQqEjjQ/viewform?usp=sf_link',
  TRUSTPILOT_REVIEW_URL: '/trustpilot_review_url',
  SPAM: '/spam-center',
  ENTERPRISE: 'https://enterprise.cointracker.com',
  COINS_PAGE: '/coins',
};

export const CREATE_URL = {
  VIEW_TAX_FIRM: (publicId: string) => `/tax-pros/firm-view/${publicId}`,
};

export const isURLActive = (
  urlKey: string | string[],
  location?: Location,
): boolean => {
  const pathname = location?.pathname || window?.location?.pathname || '';
  if (urlKey instanceof Array) {
    return urlKey.some((url) => isURLActive(url));
  }
  // This fixes the case where the MAIN_PAGE is always true for every path
  if (urlKey === '/') {
    return pathname === urlKey;
  }
  if (!urlKey?.startsWith?.('/')) {
    urlKey = '/' + urlKey;
  }
  if (pathname?.startsWith?.(urlKey + '/')) {
    return true;
  }
  return pathname === urlKey;
};

export enum ImportType {
  TRADE_IMPORTS = 2,
  TRADE_TRANSFERS = 3,
  TRANSACTION_HISTORY_IMPORT = 7,
  GENERIC_CSV_IMPORT = 1,
}
