export const lightThemeColors = {
  neutral: {
    '0': '#FFFFFF',
    '100': '#F7F8F9',
    '200': '#E9EBEA',
    '300': '#D2D3D2',
    '400': '#AFB1B0',
    '500': '#969797',
    '700': '#606160',
    '800': '#414141',
    '900': '#0D0D0D',
  },
  primary: {
    '100': '#F7F8F9',
    '200': '#EBEDF0',
    '300': '#DFE1E7',
    '400': '#CED2DB',
    '650': '#0A0B0D',
    '700': '#0A0B0D',
    '800': '#33353D',
    '900': '#0A0B0D',
  },
  purple: {
    '100': '#FEFFF5',
    '700': '#EBFF00',
    '800': '#F6FF93',
    '900': '#D9EB00',
  },
  green: {
    '100': '#C8F9D9',
    '700': '#36D76F',
    '800': '#3BEB79',
    '900': '#279B50',
  },
  amber: {
    '100': '#FFF4C4',
    '500': '#FFD000',
    '600': '#D6AF00',
    '700': '#6B5701',
    '800': '#B29200',
    '900': '#423500',
  },
  red: {
    '100': '#FFE2E2',
    '700': '#E50000',
    '800': '#F00',
    '900': '#AD0000',
  },
  'text-icon': {
    primary: '#0F182A',
    secondary: '#5B616E',
    disabled: '#6F747F',
    'primary-white': '#FFFFFF',
    'secondary-white': '#B7B8BA',
    'disabled-white': '#9C9DA1',
  },
};

export const darkThemeColors = {
  neutral: {
    '0': '#10111C',
    '100': '#1E2025',
    '200': '#2C2C2C',
    '300': '#606160',
    '400': '#7B7B7B',
    '500': '#969797',
    '700': '#D2D3D2',
    '800': '#DDD',
    '900': '#F5F7F6',
  },
  primary: {
    '100': '#1E2025',
    '200': '#33353D',
    '300': '#464B55',
    '400': '#5B616E',
    '650': '#FFF',
    '700': '#FFF',
    '800': '#EBEDF0',
    '900': '#FFF',
  },
  purple: {
    '100': '#171800',
    '700': '#7B8600',
    '800': '#575E00',
    '900': '#919E00',
  },
  green: {
    '100': '#0D351B',
    '700': '#2CAF5B',
    '800': '#279B50',
    '900': '#3BEB79',
  },
  amber: {
    '100': '#423500',
    '500': '#D6AF00',
    '600': '#FFD000',
    '700': '#FFEB8C',
    '800': '#FFDA30',
    '900': '#FFF4C4',
  },
  red: {
    '100': '#3B0000',
    '700': '#FF4747',
    '800': '#F00',
    '900': '#FF7373',
  },
  'text-icon': {
    primary: '#FFFFFF',
    secondary: '#B7B8BA',
    disabled: '#9C9DA1',
    'primary-white': '#0F182A',
    'secondary-white': '#5B616E',
    'disabled-white': '#6F747F',
  },
};
