import { ApolloProvider } from '@apollo/client';
import {
  ToastProvider as RebrandToastProvider,
  SidebarProvider,
} from '@cointracker/styleguide';
import { ToastProvider } from 'components/ToastNotification';
import { lightTheme } from 'components/colors/theme';
import { CtStatsigProvider } from 'components/statsig-helpers/CtStatsigProvider';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';
import { AuthProvider } from 'src/app/router';
import { UserContext } from 'src/app/user/context';
import Helmet from 'src/common/Helmet';
import { CostBasisProvider } from 'src/components/CostBasisProvider';
import { useGetAppInfoQuery } from 'src/types/graphql-types';
import { ThemeProvider } from 'styled-components';
import { CookieConsentWrapper } from '../components/CookieConsentWrapper';
import ConditionalAdoraProvider from './AdoraProvider';
import { client } from './ApolloClient';
import AppThemeProvider from './AppThemeProvider';
import { GlobalStyles } from './AppThemeProvider/styles';
import { AnalyticsProvider } from './analytics';
import { AppInfoContext } from './context/AppInfoContext';
import { router } from './router/Router';
import useGetUser from './user/hooks/useGetUser';
import './utils/dayjsConfig';

export const App = () => {
  const { data } = useGetAppInfoQuery();
  const appInfo = data
    ? {
        ...data.appInfo,
        isLoaded: true,
      }
    : { isLoaded: false };
  const { user } = useGetUser();
  return (
    <HelmetProvider>
      <Helmet />
      <AppInfoContext.Provider value={appInfo}>
        <AuthProvider>
          <UserContext.Provider value={user}>
            <AnalyticsProvider
              user={user}
              writeKey={process.env.SEGMENT_WEB_WRITE_KEY}
              mixpanelToken={process.env.PUBLIC_MIXPANEL_TOKEN}
            >
              <CtStatsigProvider>
                <ConditionalAdoraProvider
                  adoraOrgId={process.env.PUBLIC_ADORA_ORG_ID}
                  user={user}
                >
                  <AppThemeProvider>
                    <SidebarProvider>
                      <CookieConsentWrapper />
                      <ToastProvider>
                        <RebrandToastProvider>
                          <CostBasisProvider>
                            <RouterProvider router={router} />
                          </CostBasisProvider>
                        </RebrandToastProvider>
                      </ToastProvider>
                    </SidebarProvider>
                  </AppThemeProvider>
                </ConditionalAdoraProvider>
              </CtStatsigProvider>
            </AnalyticsProvider>
          </UserContext.Provider>
        </AuthProvider>
      </AppInfoContext.Provider>
    </HelmetProvider>
  );
};

interface SignedOutPageProps {
  children: React.ReactNode | React.ReactNode[];
}
export const SignedOutPageWrapper = ({ children }: SignedOutPageProps) => {
  return (
    <ApolloProvider client={client}>
      <SignedOutPage>{children}</SignedOutPage>
    </ApolloProvider>
  );
};
export const SignedOutPage = ({ children }: SignedOutPageProps) => {
  const { user } = useGetUser();
  return (
    <HelmetProvider>
      <Helmet />
      <AuthProvider>
        <UserContext.Provider value={user}>
          <AnalyticsProvider
            user={user}
            writeKey={process.env.SEGMENT_WEB_WRITE_KEY}
            mixpanelToken={process.env.PUBLIC_MIXPANEL_TOKEN}
          >
            <CtStatsigProvider>
              <GlobalStyles theme={lightTheme} />
              <ThemeProvider theme={lightTheme}>
                <ToastProvider>{children}</ToastProvider>
              </ThemeProvider>
            </CtStatsigProvider>
          </AnalyticsProvider>
        </UserContext.Provider>
      </AuthProvider>
    </HelmetProvider>
  );
};

export default App;
