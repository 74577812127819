import classNames from 'classnames';
import { useLottie, type LottieRef } from 'lottie-react';
import { forwardRef, type Ref } from 'react';
import {
  content,
  desktopImageOnlyStyle,
  image,
  root,
} from './TextAndImage.css';

export interface TextAndImageProps {
  children?: React.ReactNode;
  className?: string;
  desktopImageOnly?: boolean;
  imgAlt: string;
  imgClassName?: string;
  imgSrc?: string;
  isLottie?: boolean;
  lottieData?: unknown;
  lottieRefCallback?: LottieRef;
}

interface LottieImageProps {
  animationData: unknown;
  className: string;
  lottieRef?: LottieRef;
}
const LottieImage = ({
  animationData,
  className,
  lottieRef,
}: LottieImageProps) => {
  const { View } = useLottie({
    animationData,
    loop: true,
    lottieRef,
  });
  return <div className={className}>{View}</div>;
};

export const TextAndImage = forwardRef(
  (props: TextAndImageProps, ref: Ref<HTMLDivElement>) => {
    const {
      children,
      className,
      desktopImageOnly,
      imgAlt,
      imgClassName,
      imgSrc,
      isLottie,
      lottieData,
      lottieRefCallback,
      ...rest
    } = props;

    return (
      <div className={classNames(root, className)} ref={ref} {...rest}>
        <div className={content}>{children}</div>
        {isLottie ? (
          <>
            <LottieImage
              animationData={lottieData}
              lottieRef={lottieRefCallback}
              className={classNames(image, imgClassName, {
                [desktopImageOnlyStyle]: desktopImageOnly,
              })}
            />
          </>
        ) : (
          <img
            className={classNames(image, imgClassName, {
              [desktopImageOnlyStyle]: desktopImageOnly,
            })}
            src={imgSrc}
            alt={imgAlt}
          />
        )}
      </div>
    );
  },
);

TextAndImage.displayName = 'TextAndImage';
