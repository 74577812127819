/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  type EventProperties,
  type SegmentEvent,
  type UserTraits,
} from '@segment/analytics-next';
import { type User } from './types';

/**
 * Server Analytics library
 *
 * At this time we don't have a server library for segment. This is a placeholder for now, so during SSR it doesn't
 * complain
 */
export default class AnalyticsServer {
  init(writeKey: string, user: User) {}
  async load(writeKey: string, user: User) {}
  reset() {}
  identify(userId: string, userTraits: UserTraits = {}) {}
  page(
    pageCategory?: string,
    pageName?: string,
    eventProperties: EventProperties = {},
  ) {}
  track(
    eventName: string | SegmentEvent,
    eventProperties: EventProperties = {},
  ) {}
  forceFlush() {}
}
