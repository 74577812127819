import { cn, SheetTrigger, useSidebar } from '@cointracker/styleguide';
import { Button } from '@cointracker/styleguide/src/LoggedIn';
import { Body } from '@cointracker/styleguide/src/LoggedIn/Typography';
import { useGetStatus } from './hooks/useGetStatus';
import { StatusIndicator } from './StatusIndicator';
import { getStatusText } from './utils';

interface StatusButtonProps {
  iconOnly?: boolean;
  className?: string;
}

export const StatusButton = ({ iconOnly, className }: StatusButtonProps) => {
  const { status } = useGetStatus();
  const { isMobile, toggleStatusSheet, isStatusSheetOpen } = useSidebar();
  if (iconOnly) {
    return (
      <SheetTrigger asChild>
        <Button
          variant="line"
          size="small"
          className={cn(
            'flex flex-row place-items-center gap-8 md:p-0',
            className,
          )}
          transparent={!isMobile}
          onClick={() => {
            toggleStatusSheet();
          }}
        >
          <StatusIndicator status={status} />
        </Button>
      </SheetTrigger>
    );
  }

  return (
    <SheetTrigger asChild>
      <Button
        variant="line"
        size="small"
        className={cn(
          'flex w-full flex-row justify-start gap-8 md:p-0',
          {
            'border-line-secondary-pressed bg-background-default-pressed':
              isStatusSheetOpen && isMobile,
          },
          className,
        )}
        transparent={!isMobile}
        onClick={() => {
          toggleStatusSheet();
        }}
      >
        <StatusIndicator status={status} />
        <Body
          variant="body5"
          className={cn(
            'whitespace-nowrap text-text-secondary transition-opacity hover:text-text-primary group-data-[state=collapsed]:opacity-0',
            // fade animation timing
            'delay-300 duration-150 ease-in group-data-[state=collapsed]:delay-0 group-data-[state=collapsed]:ease-out',
          )}
        >
          {getStatusText(status)}
        </Body>
      </Button>
    </SheetTrigger>
  );
};
