import { cn } from '@cointracker/styleguide';
import { CheckCircle, Warning } from '@phosphor-icons/react';
import { StatusReadyState } from './hooks/useGetStatus';

interface StatusIndicatorProps {
  status: StatusReadyState;
  circleClass?: string;
}

interface SyncingCircleProps {
  className?: string;
}

const SyncingCircle = ({ className }: SyncingCircleProps) => (
  <div
    className={cn(
      'relative aspect-square h-16 rounded-full border-[5px] border-solid border-accent-subtle-slate',
      'before:border-box before:absolute before:-inset-[5px] before:animate-spin-syncing-circle before:rounded-full before:border-[5px] before:border-solid before:border-accent-bold-yellow',
      className,
    )}
  />
);

export function StatusIndicator({ status, circleClass }: StatusIndicatorProps) {
  if (status === StatusReadyState.Syncing) {
    return <SyncingCircle className={circleClass} />;
  }
  if (status === StatusReadyState.Calculating) {
    return (
      <SyncingCircle
        className={cn(
          'border-accent-bold-green before:border-accent-subtle-green',
          circleClass,
        )}
      />
    );
  }
  if (status === StatusReadyState.Failed) {
    return (
      <div className="leading-100 text-text-negative">
        <Warning weight="fill" />
      </div>
    );
  }
  if (status === StatusReadyState.Loading) {
    return (
      <SyncingCircle
        className={cn(
          'border-accent-bold-slate before:border-accent-subtle-slate',
          circleClass,
        )}
      />
    );
  }

  if (status === StatusReadyState.EmptyData) {
    return (
      <div className="h-16 w-16 rounded-full border-[5px] border-solid border-background-secondary-pressed leading-100" />
    );
  }

  return (
    <div className="h-16 w-16 leading-100 text-text-positive">
      <CheckCircle weight="fill" />
    </div>
  );
}
