import { type HTMLAttributes } from 'react';
import { cn } from '../../utils';

interface PageContainerProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
}

export const PageContainer = ({
  children,
  className,
  ...props
}: PageContainerProps) => {
  return (
    <div
      className={cn('mx-auto min-h-screen w-full max-w-screen-xl', className)}
      {...props}
    >
      <div className="mx-16 sm:mx-32 lg:mx-48 2xl:mx-64">{children}</div>
    </div>
  );
};
