import { createContext, useContext } from 'react';

export enum SnapState {
  BEFORE,
  SNAPPED,
  AFTER,
}

export const SnapStateContext = createContext<SnapState | undefined>(undefined);

export const useSnapState = (): SnapState | undefined => {
  return useContext(SnapStateContext);
};
