import { CookieConsent } from '@cointracker/ui';
import { useContext } from 'react';
import { UserContext } from 'src/app/user/context';
import { useTheme } from 'styled-components';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import { isDarkTheme } from '../colors/theme';
import { CookieGlobalStyles } from './styles';

export const CookieConsentWrapper = () => {
  const theme = useTheme();
  const isUsingDarkTheme = isDarkTheme(theme);
  const user = useContext(UserContext);
  return (
    <>
      <CookieConsent
        isDarkTheme={isUsingDarkTheme}
        loading={!user.hasFetched}
        showManageButton={user.hasFetched && !user.isAuthenticated}
      />
      <CookieGlobalStyles />
    </>
  );
};
