import { useContext } from 'react';
import { isAuthenticatedUser, UserContext } from 'src/app/user/context';
import { ViewingAsWidget } from '../';

export const ConnectedViewingAsWidget = () => {
  const user = useContext(UserContext);
  if (isAuthenticatedUser(user) && user?.email && user.isTaxProViewingClient) {
    return <ViewingAsWidget email={user?.email} />;
  }
  return null;
};
