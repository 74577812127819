import { CaretUpDown, Info } from '@phosphor-icons/react';
import { type CheckedState } from '@radix-ui/react-checkbox';
import { type SortDirection } from '@tanstack/react-table';
import { forwardRef, type HTMLAttributes, type Ref } from 'react';
import { Checkbox } from '../../../Checkbox';
import { type CheckboxVariants } from '../../../Checkbox/Checkbox';
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '../../../Popover/Popover';
import { cn } from '../../../utils';
import { Body4 } from '../../Typography';

interface TableLabelSortButtonProps {
  isSortable?: boolean;
  direction?: SortDirection | false;
  onClick?: () => void;
  className?: string;
}

interface TableLabelProps
  extends Omit<HTMLAttributes<HTMLTableCellElement>, 'onClick'>,
    TableLabelSortButtonProps {
  tooltip?: string;
  withCheckbox?: boolean;
  checked?: CheckedState;
  onCheckedChange?: (checked: boolean) => void;
  as?: React.ElementType;
  checkboxSize?: CheckboxVariants['size'];
  checkboxClassName?: string;
  disabled?: boolean;
}

export const TableLabel = forwardRef(
  (
    {
      className,
      tooltip,
      isSortable,
      direction,
      children,
      onClick,
      withCheckbox,
      checkboxSize,
      checkboxClassName,
      checked,
      onCheckedChange,
      as = 'th',
      disabled,
      ...props
    }: TableLabelProps,
    ref: Ref<HTMLTableCellElement>,
  ) => {
    const Component = as;

    return (
      <Component
        ref={ref}
        className={cn(
          'flex h-[60px] min-h-[30px] select-none items-center gap-2 px-8',
          className,
        )}
        {...props}
      >
        {withCheckbox && (
          <Checkbox
            checked={checked}
            onCheckedChange={onCheckedChange}
            className={cn('mr-10', checkboxClassName)}
            size={checkboxSize}
          />
        )}
        <Body4
          className={cn('text-text-secondary', { 'cursor-pointer': !!onClick })}
          onClick={onClick}
        >
          {children}
        </Body4>
        {tooltip && (
          <Popover>
            <PopoverTrigger asChild>
              <button disabled={disabled}>
                <Info />
              </button>
            </PopoverTrigger>
            <PopoverContent withArrow variant="purple">
              <PopoverBody>{tooltip}</PopoverBody>
            </PopoverContent>
          </Popover>
        )}
        <TabelLabelSortButton {...{ isSortable, direction, onClick }} />
      </Component>
    );
  },
);

TableLabel.displayName = 'TableLabel';

interface SortIconProps {
  direction?: SortDirection | false;
}

export const SortIcon = ({ direction }: SortIconProps) => {
  const isDesc = direction === 'desc';
  const isAsc = direction === 'asc';

  if (!direction) {
    return (
      <div>
        <CaretUpDown className={cn('h-18 w-18 text-icon-secondary')} />
      </div>
    );
  }

  // NOTE: grabbed raw SVG here instead of using the phosphor filled variant because we wanted to access separate paths
  // for different states. This is not added to the /src/icons folder to remove confusion. If we need it elsewhere though
  // we can move then.
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={cn(
        'h-18 w-18 text-icon-secondary',
        isDesc && '[&_path:first-of-type]:fill-icon-primary-foreground',
        isAsc && '[&_path:last-of-type]:fill-icon-primary-foreground',
      )}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.9236 21.6175C22.9994 21.8002 23.0193 22.0013 22.9808 22.1953C22.9423 22.3893 22.8471 22.5676 22.7073 22.7075L16.7073 28.7075C16.6144 28.8005 16.5041 28.8742 16.3828 28.9246C16.2614 28.9749 16.1312 29.0008 15.9998 29.0008C15.8684 29.0008 15.7383 28.9749 15.6169 28.9246C15.4955 28.8742 15.3852 28.8005 15.2923 28.7075L9.29231 22.7075C9.1523 22.5676 9.05693 22.3894 9.01828 22.1953C8.97963 22.0012 8.99944 21.8 9.07519 21.6172C9.15094 21.4344 9.27924 21.2782 9.44383 21.1683C9.60842 21.0584 9.80192 20.9998 9.99981 21H21.9998C22.1976 21 22.3909 21.0587 22.5553 21.1686C22.7198 21.2786 22.8479 21.4348 22.9236 21.6175Z"
        fill="currentColor"
      />
      <path
        d="M9.99981 10.9998H21.9998C22.1977 11 22.3912 10.9414 22.5558 10.8315C22.7204 10.7217 22.8487 10.5654 22.9244 10.3826C23.0002 10.1998 23.02 9.99859 22.9813 9.8045C22.9427 9.61042 22.8473 9.43217 22.7073 9.29231L16.7073 3.29231C16.6144 3.19933 16.5041 3.12557 16.3828 3.07525C16.2614 3.02493 16.1312 2.99902 15.9998 2.99902C15.8684 2.99902 15.7383 3.02493 15.6169 3.07525C15.4955 3.12557 15.3852 3.19933 15.2923 3.29231L9.29231 9.29231C9.1523 9.43217 9.05693 9.61042 9.01828 9.8045C8.97963 9.99859 8.99944 10.1998 9.07519 10.3826C9.15094 10.5654 9.27924 10.7217 9.44383 10.8315C9.60842 10.9414 9.80192 11 9.99981 10.9998Z"
        fill="currentColor"
      />
    </svg>
  );
};

// Note we don't pass className to this button above, but we do want to allow it for styling purposes if used independently.
export function TabelLabelSortButton({
  isSortable,
  direction,
  onClick,
  className,
  ...props
}: TableLabelSortButtonProps) {
  return isSortable ? (
    <button className={className} {...props} onClick={onClick}>
      <SortIcon direction={direction} />
    </button>
  ) : null;
}
