import { Bell, X } from '@phosphor-icons/react';
import { isEmpty } from 'lodash-es';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useState,
  type Ref,
} from 'react';
import { v1 as uuid } from 'uuid';
import { Body2 } from '../LoggedIn';
import useResizeObserver from '../hooks/useResizeObserver';
import { cn } from '../utils';
import { Status } from './constants';
import {
  ToastContext,
  type ToastContextType,
  type ToastNotificationProps,
} from './context';

interface ToastProviderProps {
  children: React.ReactNode;
  // we hide the notifications display when there's no notifications for test scenarios, because it would create
  // an empty div on components that would render nothing, and it was a bit missleading on why, since the ToastProvider
  // is always rendered on MockedApps
  hideDisplayWhenEmpty?: boolean;
}

export const ToastProvider = ({
  children,
  hideDisplayWhenEmpty,
}: ToastProviderProps) => {
  const [notifications, setNotifications] = useState<ToastNotificationProps[]>(
    [],
  );
  const [snackbarContext, setToastState] = useState<ToastContextType>({
    showNotification: (notification) => {
      const newNotification = { ...notification, id: uuid() };
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        newNotification,
      ]);
      return newNotification;
    },
    updateNotification: (updatedNotification) => {
      setNotifications((prevNotifications) => {
        if (!prevNotifications || isEmpty(prevNotifications)) {
          return [];
        }
        return prevNotifications.map((notification) => {
          if (notification.id === updatedNotification.id) {
            return updatedNotification;
          }
          return notification;
        });
      });
    },
    removeNotification: (notificationId) => {
      setNotifications((prevNotifications) =>
        prevNotifications.filter(
          (notification) => notification.id !== notificationId,
        ),
      );
    },
    containerHeight: 0,
  });

  const onContainerResize = useCallback(
    (container: HTMLElement) => {
      setToastState((state) => ({
        ...state,
        containerHeight: container?.getBoundingClientRect().height,
      }));
    },
    [setToastState],
  );

  // using only the ref was not enough because when the notifications array changed, the DOM was not updated yet with
  // the expected height. This is a more complete solution
  const displayRef = useResizeObserver(onContainerResize);

  const shouldHideDisplay = hideDisplayWhenEmpty && isEmpty(notifications);
  return (
    <ToastContext.Provider value={snackbarContext}>
      {!shouldHideDisplay && (
        <ToastNotificationsDisplay
          notifications={notifications}
          ref={displayRef}
        />
      )}
      {children}
    </ToastContext.Provider>
  );
};

interface ToastNotificationsDisplayProps {
  notifications: ToastNotificationProps[];
  // In transactions page we render two separate ToastNotificationDisplay components
  // so we use the marginTop property to place them one below the other
  marginTop?: number;
}
export const ToastNotificationsDisplay = forwardRef(
  (
    { notifications, marginTop }: ToastNotificationsDisplayProps,
    ref: Ref<HTMLElement>,
  ) => {
    return (
      <div
        ref={ref as Ref<HTMLDivElement>}
        style={{ marginTop }}
        className="fixed bottom-32 right-32 z-10 flex flex-col items-end sm:bottom-16 sm:right-16"
      >
        {notifications.map((toastProps) => (
          <Toast key={toastProps.id} {...toastProps} />
        ))}
      </div>
    );
  },
);

ToastNotificationsDisplay.displayName = 'ToastNotificationsDisplay';

export const Toast = ({
  message,
  status,
  delay = 3000,
  isClosable = true,
}: ToastNotificationProps) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true); // Initialize component as hidden to get nice transition effect.
    if (delay >= 0) {
      const hiderTimeout = setTimeout(() => setShow(false), delay);
      return () => clearTimeout(hiderTimeout);
    }
  }, [delay]);

  return (
    <article
      className={cn(
        'bg-background-pop-up border-line-card rounded-8 shadow-elevation-1 relative flex min-h-[56px] max-w-[800px] items-center overflow-y-hidden border py-8 pl-16 pr-24 transition-all duration-150 ease-out',
        {
          'mb-10 max-h-[150px] opacity-100': show,
          'mb-0 max-h-0 border-none opacity-0': !show,
        },
      )}
    >
      <div className="flex items-center gap-16">
        <div className="bg-background-card-highlight flex h-32 w-32 items-center justify-center rounded-full">
          <Bell
            weight="fill"
            className={cn({
              'text-accent-bold-blue': status === Status.Info,
              'text-icon-positive': status === Status.Success,
              'text-icon-negative': status === Status.Error,
            })}
          />
        </div>
        <Body2
          className="text-text-secondary flex-1"
          data-testid={'toast-notification-text'}
        >
          {message}
        </Body2>
        {isClosable && (
          <button
            className="text-text-primary cursor-pointer"
            onClick={() => setShow(false)}
          >
            <X weight="bold" />
          </button>
        )}
      </div>
    </article>
  );
};
