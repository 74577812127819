import { cn } from '@cointracker/styleguide/src/utils';
import { type VariantProps } from 'class-variance-authority';
import React, {
  forwardRef,
  useCallback,
  type ReactNode,
  type Ref,
} from 'react';
import SpinnerIcon from '../../icons/spinner.svg?react';
import { bodyVariants } from '../Typography/variants';
import { buttonSpinnerVariants, buttonVariants } from './buttonVariants';

export type ButtonVariants = VariantProps<typeof buttonVariants>;

export type ButtonProps = {
  children?: ReactNode;
  href?: string;
  disabled?: boolean;
  className?: string;
  fluid?: boolean;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  role?: string;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
} & ButtonVariants &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = forwardRef(
  (
    {
      children,
      className,
      onClick,
      href,
      variant,
      fluid,
      transparent,
      loading,
      hover,
      pressed,
      size = 'large',
      type = 'button',
      ...props
    }: ButtonProps,
    forwardedRef: Ref<HTMLButtonElement>,
  ) => {
    const onClickHandler: React.MouseEventHandler<HTMLButtonElement> =
      useCallback(
        (e) => {
          if (typeof window === 'undefined') return;
          if (href) {
            window.location.href = href;
          } else if (onClick) {
            onClick(e);
          } else {
            console.warn('Button clicked, but no href or onClick provided!');
          }
        },
        [href, onClick],
      );

    return (
      <button
        ref={forwardedRef}
        type={type}
        onClick={onClickHandler}
        className={cn(
          buttonVariants({
            variant,
            fluid,
            size,
            hover,
            pressed,
            transparent,
            loading,
          }),
          bodyVariants({
            variant:
              size === 'large'
                ? 'body1'
                : size === 'medium'
                  ? 'body2'
                  : 'body3',
          }),
          'relative gap-8',
          {
            '!text-transparent': loading,
          },
          className,
        )}
        {...props}
        disabled={props.disabled}
      >
        {loading && (
          <SpinnerIcon
            data-testid="loading-spinner"
            className={cn(
              buttonSpinnerVariants({ variant, transparent }),
              'absolute bottom-0 left-0 right-0 top-0 m-auto',
            )}
          />
        )}
        {children}
      </button>
    );
  },
);

Button.displayName = 'Button';
