import {
  cn,
  Sidebar as SidebarComponent,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuItem,
  SidebarSeparator,
  SidebarTrigger,
  useSidebar,
} from '@cointracker/styleguide';
import RebrandLogoIcon from '@cointracker/styleguide/src/icons/logo-icon.svg?react';
import { Body } from '@cointracker/styleguide/src/LoggedIn/Typography';
import { ScrollArea } from '@cointracker/styleguide/src/sidebar/scroll-area';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@cointracker/styleguide/src/sidebar/tooltip';
import { CaretRight } from '@phosphor-icons/react';
import { useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { AuthenticatedUser, UserContext } from 'src/app/user/context';
import { AccountBar } from './AccountBar';
import { Status } from './StatusSection';
import { TopBar } from './TopBar';
import {
  getNavigationItems,
  getTaxPageUrl,
  isAuthenticatedUser,
} from './utils';

export const Sidebar = () => {
  const location = useLocation();
  const user = useContext(UserContext);
  const { state, isMobile, setOpenMobile } = useSidebar();

  if (!isAuthenticatedUser(user)) {
    // if user isn't authenticated, we don't render the sidebar as it's never needed in logged out pages.
    return null;
  }
  const taxPageURL = getTaxPageUrl(
    (user as AuthenticatedUser)?.displayUser?.taxYearToUse,
  );
  const navigationItems = getNavigationItems({
    taxPageUrl: taxPageURL,
    currentPathName: location.pathname,
  });

  return (
    <SidebarComponent collapsible="icon" className="">
      <SidebarHeader>
        <SidebarTrigger className="mx-4 rounded-[4px] p-4 text-icon-subtle hover:bg-background-default-hover" />
        <NavLink className="self-start" to="/">
          <RebrandLogoIcon className="h-34 w-34 text-text-primary" />
        </NavLink>
      </SidebarHeader>
      <TopBar />
      <SidebarContent>
        <ScrollArea className="h-full overflow-x-hidden px-32">
          <SidebarMenu className="py-24 md:pt-0">
            <SidebarMenuItem className="hidden md:block">
              <Status className="h-22" />
            </SidebarMenuItem>
            {navigationItems.map((item) =>
              item.slug === 'break' ? (
                <SidebarSeparator
                  key={item.title}
                  orientation="horizontal"
                  className="-mx-[6px] w-auto min-w-30 bg-line-tertiary group-data-[state=collapsed]:w-full"
                />
              ) : (
                <SidebarMenuItem key={item.title}>
                  <NavLink
                    to={item.slug}
                    onClick={(e) => {
                      !item.slug && e.preventDefault();
                      if (isMobile) {
                        setOpenMobile(false);
                      }
                    }}
                    aria-label={item.title}
                    className={cn(
                      'flex h-22 cursor-pointer flex-row place-items-center gap-16 text-text-secondary hover:text-text-primary-hover active:text-accent-bold-blue md:gap-8',
                      item.isActive && 'text-text-primary',
                    )}
                  >
                    <Tooltip>
                      <TooltipTrigger>
                        {item.icon({
                          weight: item.isActive ? 'fill' : 'regular',
                        })}
                      </TooltipTrigger>
                      <TooltipContent
                        side="right"
                        align="center"
                        className={state === 'expanded' ? 'hidden' : ''}
                      >
                        <Body
                          className="text-text-primary-foreground-inverse"
                          variant="body5"
                        >
                          {item.title}
                        </Body>
                        <TooltipArrow width={11} height={5} />
                      </TooltipContent>
                    </Tooltip>
                    <Body
                      variant={isMobile ? 'body1' : 'body3'}
                      className={cn(
                        'whitespace-nowrap transition-opacity group-data-[state=collapsed]:opacity-0',
                        // fade animation timing
                        'delay-300 duration-150 ease-in group-data-[state=collapsed]:delay-0 group-data-[state=collapsed]:ease-out',
                      )}
                    >
                      {item.title}
                    </Body>
                    <CaretRight
                      className="ml-auto text-text-primary-foreground md:hidden"
                      weight="bold"
                      size={20}
                    />
                  </NavLink>
                </SidebarMenuItem>
              ),
            )}
            <SidebarSeparator
              orientation="horizontal"
              className="bg-line-tertiary md:hidden"
            />
          </SidebarMenu>
        </ScrollArea>
      </SidebarContent>
      <SidebarFooter>
        <AccountBar />
      </SidebarFooter>
    </SidebarComponent>
  );
};
