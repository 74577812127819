import * as PopoverPrimitive from '@radix-ui/react-popover';
import { cva, type VariantProps } from 'class-variance-authority';
import { forwardRef, type Ref } from 'react';
import { Body1, Body3 } from '../LoggedIn';
import { cn } from '../utils';

export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = PopoverPrimitive.Trigger;
export const PopoverAnchor = PopoverPrimitive.Anchor;
export const PopoverClose = PopoverPrimitive.Close;

export const PopoverTitle = Body1;
export const PopoverBody = Body3;

export type PopoverContentProps = PopoverPrimitive.PopoverContentProps &
  PopoverVariants & {
    withArrow?: boolean;
  };

export const PopoverContent = forwardRef(
  (
    {
      children,
      withArrow = false,
      variant = 'white',
      className,
      ...props
    }: PopoverContentProps,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    forwardedRef: Ref<any>,
  ) => (
    <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content
        {...props}
        ref={forwardedRef}
        className={cn(popoverVariants({ variant }), className)}
      >
        {children}
        {withArrow && (
          <PopoverPrimitive.Arrow
            className={cn({
              'fill-line-primary': variant === 'purple',
              'fill-line-secondary': variant === 'white',
            })}
          />
        )}
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Portal>
  ),
);
PopoverContent.displayName = 'PopoverContent';

export type PopoverVariants = VariantProps<typeof popoverVariants>;

const popoverVariants = cva(
  [
    'rounded-8 shadow-elevation-1 z-10 flex max-w-[342px] flex-col gap-12 border p-16',
    'data-[state=delayed-open]:animate-in',
  ],
  {
    variants: {
      variant: {
        white: 'border-line-primary bg-background-pop-up',
        purple: 'border-line-secondary bg-background-card-highlight',
      },
    },
    defaultVariants: {
      variant: 'white',
    },
  },
);
