import styled from 'styled-components';
import { variantStyles } from './variants';

export const BodyBig = styled.span<{
  $variant?: string;
  $fontWeight?: number | string;
}>`
  font-style: normal;
  font-weight: ${({ $fontWeight }) => $fontWeight ?? '500'};
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.42px;
  ${({ theme, $variant }) => variantStyles(theme, $variant)}

  .rebrand & {
    font-family: NB-International;
    font-size: 16px;
    font-weight: 400;
    line-height: 17.2px;
    letter-spacing: 0.004em;
  }
`;

export const BodyMid = styled.p<{
  $variant?: string;
  $noMargin?: boolean;
  $fontWeight?: number | string;
}>`
  font-style: normal;
  font-weight: ${({ $fontWeight }) => $fontWeight ?? 'normal'};
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.116667px;
  ${({ $noMargin }) => ($noMargin ? 'margin: 0;' : '')}
  ${(props) => variantStyles(props.theme, props.$variant)}

  .rebrand & {
    font-family: NB-International;
    font-size: 14px;
    font-weight: 400;
    line-height: 15.05px;
    letter-spacing: 0.004em;
  }
`;

export const BodyMidSpan = styled.span<{
  $variant?: string;
  $noMargin?: boolean;
  $fontWeight?: number | string;
}>`
  font-style: normal;
  font-weight: ${({ $fontWeight }) => $fontWeight ?? 'normal'};
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.116667px;
  ${({ $noMargin }) => ($noMargin ? 'margin: 0;' : '')}
  ${(props) => variantStyles(props.theme, props.$variant)}

  .rebrand & {
    font-family: NB-International;
    font-size: 14px;
    font-weight: 400;
    line-height: 15.05px;
    letter-spacing: 0.004em;
  }
`;

export const BodySmall = styled.span<{ $variant?: string }>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.116667px;
  ${({ theme, $variant }) => variantStyles(theme, $variant)}

  .rebrand & {
    font-family: NB-International;
    font-size: 12px;
    font-weight: 400;
    line-height: 12.9px;
    letter-spacing: 0.004em;
  }
`;
