import { H3 } from 'components/typography/headlines';
import React from 'react';
import { Container } from './style';

interface ProvisionPageProps {
  isSuccess: boolean;
}
export default function ProvisionPage({ isSuccess }: ProvisionPageProps) {
  const message = isSuccess
    ? 'You are now correctly provisioned. Thank you!'
    : 'There was an unknown error provisioning your user. Contact Carbon.';
  return (
    <Container>
      <H3 $variant="primary">{message}</H3>
    </Container>
  );
}
