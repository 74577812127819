import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import classNames from 'classnames';
import { type ReactNode } from 'react';
import { Body5 } from '../LoggedIn';

export interface TooltipProps
  extends TooltipPrimitive.TooltipProps,
    Omit<TooltipPrimitive.TooltipContentProps, 'content'> {
  content: ReactNode;
  maxWidth?: number;
}

export function Tooltip({
  children,
  content,
  open,
  defaultOpen,
  onOpenChange,
  maxWidth,
  side,
  hidden,
  ...props
}: TooltipProps) {
  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root
        open={open}
        defaultOpen={defaultOpen}
        onOpenChange={onOpenChange}
        delayDuration={100}
      >
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <TooltipPrimitive.Content
            side={side}
            align="center"
            style={{ maxWidth }}
            className={classNames(
              'rounded-8 shadow-elevation-1 bg-background-pop-up border-line-card z-10 flex max-w-[342px] flex-col gap-12 border px-16 py-12',
              'data-[state=delayed-open]:animate-in',
              hidden && 'hidden',
            )}
            {...props}
          >
            <Body5>{content}</Body5>
            <TooltipPrimitive.Arrow
              className="stroke-line-card relative top-[-1px] fill-transparent stroke-2"
              width={11}
              height={5}
            />
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
}

export default Tooltip;
