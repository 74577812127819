import { createRoutesFromElements, Route } from 'react-router-dom';
import { URLS } from '../../../common/urls';
import ProvisionPage from '../../../pages/Provision';
import ExternalRedirect from '../ExternalRedirect';
import { Lazy } from '../Lazy';
import { NavigateWithParams } from '../NavigateWithParams';

/**
 * ####################### IMPORTANT #######################
 * Rules in this file:
 * 1. Only add routes to this file. Do not import anything else above!!
 * 2. Only add async imports from the pages folder!
 * 3. If you add a public route, make sure to add it to the sitemap (apps/landing/scripts/sitemap.ts). This is for SEO purposes.
 *
 * Why?
 * This file is used also by the cf-reverse-proxy to proxy routes. Because of this
 * it gets bundled into a Cloudflare Worker. The Cloudflare Worker has a size limit
 * thus we need to keep the bundle size as small as possible. We mock the Lazy and Pages
 * imports, so that we don't import the entire client side app!
 *
 * If you need to add anything besides a route, specifically if you add any new import above in
 * this file or async import from a separate folder, please first check with Product Platform Pod!
 *
 * ####################### IMPORTANT #######################
 */

export const Routes = (
  <>
    <Route index element={<NavigateWithParams replace to="/home" />} />
    <Route
      path="/home"
      element={<Lazy page={() => import('../../../pages/Rebrand/Dashboard')} />}
    />
    <Route
      path="/dashboard"
      element={<Lazy page={() => import('../../../pages/Rebrand/Dashboard')} />}
    />
    <Route
      path="/onboarding/:path?"
      element={
        <Lazy
          page={() => import('../../../pages/Rebrand/Onboarding')}
          forbidViewAs
        />
      }
    />
    <Route
      path="/performance"
      element={
        <Lazy page={() => import('../../../pages/Rebrand/Performance')} />
      }
    />
    <Route
      path="/transactions"
      element={
        <Lazy page={() => import('../../../pages/Rebrand/TransactionsPage')} />
      }
    />
    <Route
      path={URLS.ADD_TRANSACTION}
      element={
        <Lazy page={() => import('../../../pages/Rebrand/TransactionForm')} />
      }
    />
    <Route
      path="/transactions/:transactionId"
      element={
        <Lazy page={() => import('../../../pages/Rebrand/TransactionForm')} />
      }
    />
    <Route
      path="/prices"
      handle={{ isPublic: true }}
      element={
        <Lazy isPublic page={() => import('../../../pages/Rebrand/Prices')} />
      }
    >
      <Route
        path="all"
        handle={{ isPublic: true }}
        element={
          <Lazy isPublic page={() => import('../../../pages/Rebrand/Prices')} />
        }
      />
      <Route
        path="watchlist"
        handle={{ isPublic: true }}
        element={
          <Lazy isPublic page={() => import('../../../pages/Rebrand/Prices')} />
        }
      />
    </Route>
    <Route
      path="/coins"
      handle={{ isPublic: true }}
      element={
        <Lazy isPublic page={() => import('../../../pages/Rebrand/Prices')} />
      }
    >
      <Route
        path="trading-volume"
        handle={{ isPublic: true }}
        element={
          <Lazy isPublic page={() => import('../../../pages/Rebrand/Prices')} />
        }
      />
      <Route
        path="gainers-and-losers"
        handle={{ isPublic: true }}
        element={
          <Lazy isPublic page={() => import('../../../pages/Rebrand/Prices')} />
        }
      />
      <Route
        path="market-cap"
        handle={{ isPublic: true }}
        element={
          <Lazy isPublic page={() => import('../../../pages/Rebrand/Prices')} />
        }
      />
      <Route
        path="live-charts"
        handle={{ isPublic: true }}
        element={
          <Lazy isPublic page={() => import('../../../pages/Rebrand/Prices')} />
        }
      />
      <Route
        path="most-volatile"
        handle={{ isPublic: true }}
        element={
          <Lazy isPublic page={() => import('../../../pages/Rebrand/Prices')} />
        }
      />
    </Route>
    <Route
      path="/price/:symbolNameOrSlug"
      handle={{ isPublic: true }}
      element={
        <Lazy page={() => import('../../../pages/Rebrand/Price')} isPublic />
      }
    />
    <Route
      path="/user/profile"
      element={<Lazy page={() => import('../../../pages/Rebrand/Settings')} />}
    >
      <Route
        path=":page"
        element={
          <Lazy page={() => import('../../../pages/Rebrand/Settings')} />
        }
      />
    </Route>
    <Route
      path="/account-health"
      element={
        <Lazy page={() => import('../../../pages/Rebrand/Reconciliation')} />
      }
    >
      <Route
        path=":page"
        element={
          <Lazy page={() => import('../../../pages/Rebrand/Reconciliation')} />
        }
      />
    </Route>
    <Route
      path="/spam-center"
      element={<Lazy page={() => import('../../../pages/Rebrand/Spam')} />}
    >
      <Route
        path=":page"
        element={<Lazy page={() => import('../../../pages/Rebrand/Spam')} />}
      />
    </Route>
    <Route
      path="/tax-lots/:symbolNameOrSlug"
      element={<Lazy page={() => import('../../../pages/Rebrand/TaxLots')} />}
    />
    <Route
      path="/tax-pros"
      handle={{ isPublic: true }}
      element={<ExternalRedirect to="/tax-professionals" />}
    />
    <Route
      path={URLS.VIEW_TAX_PRO_DASHBOARD}
      element={
        <Lazy page={() => import('../../../pages/Rebrand/TaxPros/Dashboard')} />
      }
    />
    <Route
      path={URLS.VIEW_TAX_PROS_INCOMING_INVITES}
      element={
        <Lazy
          page={() => import('../../../pages/Rebrand/TaxPros/IncomingInvites')}
        />
      }
    />
    <Route
      path={URLS.VIEW_TAX_PRO_CLIENTS}
      element={
        <Lazy
          page={() => import('../../../pages/Rebrand/TaxPros/ClientViewPage')}
        />
      }
    />
    <Route
      path={URLS.VIEW_TAX_PRO}
      element={
        <Lazy
          page={() => import('../../../pages/Rebrand/TaxPros/TaxProViewPage')}
        />
      }
    />
    <Route
      path={'/tax-pros/firm-view/:taxFirmPublicId'}
      element={
        <Lazy
          page={() => import('../../../pages/Rebrand/TaxPros/FirmViewPage')}
        />
      }
    />
    <Route
      path={URLS.LOT_IMPORTER}
      element={<Lazy page={() => import('../../../pages/LotImporter')} />}
    />
    <Route
      path={URLS.LOT_IMPORTER_WAITING}
      element={
        <Lazy page={() => import('../../../pages/LotImporterWaiting')} />
      }
    />
    <Route
      path="/promotion/coinbase-one"
      handle={{ isPublic: true }}
      element={
        <Lazy
          isPublic
          page={() => import('../../../pages/Rebrand/Promotion/CoinbaseOne')}
        />
      }
    />
    <Route
      path="/plans"
      handle={{ isPublic: true }}
      element={
        <Lazy
          isPublic
          page={() => import('../../../pages/Rebrand/UnifiedSubscription')}
        />
      }
    />
    <Route
      path="/portfolio/subscription"
      element={<NavigateWithParams replace to="/plans" />}
    />
    <Route
      path={URLS.PORTFOLIO_PAGE}
      element={<Lazy page={() => import('../../../pages/Portfolio')} />}
    />
    <Route
      path="/subscription"
      element={<NavigateWithParams replace to="/plans" />}
    />
    <Route
      path="/wallets"
      element={<Lazy page={() => import('../../../pages/Rebrand/Wallets')} />}
    >
      <Route
        path=":walletType?/:walletId?"
        element={
          <Lazy
            page={() =>
              import('../../../pages/Rebrand/Wallets/WalletsContainer')
            }
          />
        }
      />
    </Route>
    <Route
      path="/wallet/:nameOrSymbol"
      handle={{ isPublic: true }}
      element={
        <Lazy
          isPublic
          page={() => import('../../../pages/Rebrand/WalletBalance')}
        />
      }
    />
    <Route
      path="/accounts/:exchangeId"
      element={<Lazy page={() => import('../../../pages/UpdateExchange')} />}
    />
    <Route
      path="/accounts/:exchangeId/import"
      element={
        <Lazy
          page={() =>
            import('../../../pages/Rebrand/ImportExchangeTransactions')
          }
          forbidViewAs
        />
      }
    />
    <Route
      path="/custom_wallets/:walletId/import"
      element={
        <Lazy
          page={() =>
            import('../../../pages/Rebrand/ImportCustomWalletTransactions')
          }
          forbidViewAs
        />
      }
    />
    <Route
      path="/tax/:taxYear/plans"
      element={<NavigateWithParams replace to="/plans" />}
    />
    <Route
      path="/tax/:taxYear"
      element={<Lazy page={() => import('../../../pages/Rebrand/Tax')} />}
    />
    <Route
      path="/currencies/custom"
      element={
        <Lazy page={() => import('../../../pages/Rebrand/CustomCurrencies')} />
      }
    />
    <Route
      path="/reports"
      element={<Lazy page={() => import('../../../pages/Reports')} />}
    />
    <Route
      path={URLS.TAX_LOSS_HARVEST}
      element={
        <Lazy page={() => import('../../../pages/Rebrand/TaxLossHarvesting')} />
      }
    />
    <Route
      path="/admin/account-types"
      element={
        <Lazy
          page={() => import('../../../pages/Rebrand/Admin/AccountTypes')}
          allowOnlyAdmins
        />
      }
    />
    <Route
      path="/admin/tax-for-pros"
      element={
        <Lazy
          page={() => import('../../../pages/tax-pros/FirmsAdminPage')}
          allowOnlyAdmins
        />
      }
    />
    <Route
      path="/email-confirmation"
      handle={{ isPublic: true }}
      element={
        <Lazy
          isPublic
          page={() => import('../../../pages/EmailConfirmation')}
        />
      }
    />
    <Route
      path="/support"
      handle={{ isPublic: true }}
      element={<ExternalRedirect to="https://support.cointracker.io" />}
    />
    <Route
      path="/provision/success"
      handle={{ isPublic: true }}
      element={<ProvisionPage isSuccess />}
    />
    <Route
      path="/provision/error"
      handle={{ isPublic: true }}
      element={<ProvisionPage isSuccess={false} />}
    />
  </>
);

// Allows us to not have to import from Router which imports Root.
// This fixes a circular dependency between NavLink and Root.
// More explanation in this PR: https://github.com/coin-tracker/coin-tracker-server/pull/11311
export const routes = createRoutesFromElements(<Route>{Routes}</Route>);
